import { combineReducers } from 'redux';
import { clinicReducer } from './clinic/slice';
import { ClinicState, initialClinicState } from './clinic/state';
import { clinicListReducer } from './clinic-list/slice';
import { ClinicListState, initialClinicListState } from './clinic-list/state';

export type PartnersState = {
  clinic: ClinicState;
  clinicList: ClinicListState;
};

export const initialPartnersState: PartnersState = {
  clinic: initialClinicState,
  clinicList: initialClinicListState,
};

export const partnersReducers = combineReducers({
  clinic: clinicReducer,
  clinicList: clinicListReducer,
});
