import { CheckoutAddressFormData, CheckoutState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateCheckoutAddressReducer = (
  state: CheckoutState,
  action: PayloadAction<CheckoutAddressFormData>
) => {
  state.formData.address = action.payload;

  appStorage.set(AppStorageKey.CHECKOUT_STATE, state);
};
