import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { FunnelProductTypeOption } from '../../../../../components';
import { AppStorageKey, appStorage } from '../../../../../helpers';

export const setProductTypeReducer = (
  state: FunnelState,
  action: PayloadAction<FunnelProductTypeOption>
) => {
  state.productType = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
