import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const ToastBaseStyles = css<{ theme: StyledTheme }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  height: 7.5rem;
  width: 20rem;
  margin: 0.5rem 0 0 0.5rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.palette.primaryBackground.color};
  background-color: ${({ theme }) => theme.palette.secondary.color};
  z-index: 2000;

  &::before {
    content: ' ';
    position: absolute;
    background: ${({ theme }) => theme.palette.primary.color};
    width: 0.5rem;
    height: 0.5rem;
    top: 0.75rem;
    left: -0.25rem;
    transform: rotate(45deg);
  }

  .toast-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 900;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0 0.15rem 0.5rem;
    border-bottom: 1px solid
      ${({ theme }) => theme.palette.primaryBackground.color};

    .toast-title {
      margin-top: 0.5rem;
      margin-left: 0.25rem;
      flex-basis: 95%;
      display: flex;

      .toast-title-icon {
        margin-top: 0.25rem;
      }

      .toast-title-text {
        font-size: 1.1rem;
        margin-left: 0.5rem;
      }
    }

    .toast-close {
      flex-basis: 5%;
      margin-top: 0.5rem;

      span {
        cursor: pointer;
        font-size: 1.5rem;
      }
    }
  }

  .toast-content {
    p {
      margin: 0.75rem 0.5rem 0rem;
      padding: 0 0.5rem;
      color: ${({ theme }) => theme.palette.primaryBackground.color};
    }
  }
`;
