import { CheckoutRegisterFormData, CheckoutState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateCheckoutRegistrationFormReducer = (
  state: CheckoutState,
  action: PayloadAction<CheckoutRegisterFormData>
) => {
  state.formData.registration = action.payload;

  appStorage.set(AppStorageKey.CHECKOUT_STATE, state);
};
