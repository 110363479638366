import { CartState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateCartReducer = (
  state: CartState,
  action: PayloadAction<Cart>
) => {
  const cart = action.payload;

  const doctor = state.includesDoctor ? state.cart?.doctor : undefined;
  const prescription = cart.prescription
    ? cart.prescription
    : state.includesPrescription
    ? state.cart?.prescription
    : undefined;

  state.cart = { ...cart, doctor, prescription };

  state.countProductVariants = (state.cart?.productVariants || []).length || 0;
  state.countTrials = (state.cart?.trials || []).length || 0;
  state.countItems = state.countProductVariants + state.countBundles;

  state.includesTrial = state.countTrials > 0 ? true : false;
  state.includesBundle = false;
  state.includesLenses = state.includesTrial ? true : false;
  state.includesBLG = false;
  state.includesDrops = false;
  state.includesDoctor = !!state.cart?.doctor;
  state.includesPrescription = !!(
    state.cart?.prescription &&
    (state.cart!.prescription?.leftEyeProductVariant ||
      state.cart!.prescription?.rightEyeProductVariant)
  );

  state.includesTax = state.cart.taxPrice > 0;

  if (
    state.includesDoctor &&
    state.includesPrescription &&
    state.includesTrial
  ) {
    state.countItems++;
  }

  for (const i in state.cart?.productVariants) {
    const cartVariant = state.cart.productVariants[i];

    if (cartVariant.productVariant.handle.includes('lenses')) {
      state.includesLenses = true;

      if (
        !state.includesPrescription ||
        (state.cart?.region === 'US' && !state.includesDoctor)
      ) {
        state.countItems--;
        state.countProductVariants--;
      }
    }

    if (
      cartVariant.productVariant.handle.includes('knox|murphy|nikka|stacia')
    ) {
      state.includesBLG = true;
    }

    if (cartVariant.productVariant.handle.includes('drops')) {
      state.includesDrops = true;
    }
  }

  for (const x in state.cart?.trials) {
    const cartTrial = state.cart.trials[x];

    if (cartTrial.trial.handle.includes('bundle')) {
      state.includesDrops = true;
      state.includesBundle = true;
    }
  }

  state.includesBothEyes = !!(
    state.includesPrescription &&
    state.cart.prescription?.leftEyeProductVariant &&
    state.cart.prescription?.rightEyeProductVariant
  );

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
