import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';
import { ToastStyleClass, ToastTemplate } from '../../../components';

export interface NotificationPageToast {
  linger?: number;
  template?: ToastTemplate;
  styleClasses: ToastStyleClass[];
  top: number;
  id: string;
  title: string;
  message: string;
}

export interface NotificationPageState {
  toasts: NotificationPageToast[];
}

export const cleanNotificationPageState: NotificationPageState = { toasts: [] };

export const initialNotificationPageState = appStorage.get(
  AppStorageKey.PAGE_NOTIFICATION_STATE,
  cleanNotificationPageState
) as NotificationPageState;

export const notificationPageSlice = createSlice({
  name: 'NotificationPage',
  initialState: initialNotificationPageState,
  reducers: {
    add: (
      state: NotificationPageState,
      action: PayloadAction<NotificationPageToast>
    ) => {
      state.toasts = [...state.toasts, action.payload];

      appStorage.set(AppStorageKey.PAGE_NOTIFICATION_STATE, state);

      return state;
    },
    remove: (state: NotificationPageState, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );

      appStorage.set(AppStorageKey.PAGE_NOTIFICATION_STATE, state);

      return state;
    },
  },
});
