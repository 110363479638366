import { DoctorListSearchState, DoctorListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const loadingDoctorListReducer = (
  state: DoctorListState,
  action?: PayloadAction<DoctorListSearchState>
) => {
  state.loading = true;

  if (action) {
    state.search = action.payload;
  }
};
