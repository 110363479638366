import { useDispatch, useSelector } from 'react-redux';
import { ToastStyleClass } from '../../../../components';
import {
  notificationPageSlice,
  NotificationPageToast,
  StoreState,
} from '../../../redux';

export type PageNotificationType = 'default' | 'success' | 'warning' | 'error';

export interface PageNotification {
  type: PageNotificationType;
  styleClasses: ToastStyleClass[];
  title: string;
  message: string;
  linger?: number;
}

export interface PageNotificationEffect {
  add: (dialog: PageNotification) => void;
  remove: (id: string) => void;
  toasts: NotificationPageToast[];
}

export const usePageNotificationEffect = (): PageNotificationEffect => {
  const { toasts } = useSelector(
    (state: StoreState) => state.page.notification
  );

  const dispatch = useDispatch();

  const add = (dialog: PageNotification): void => {
    const id = Math.random().toString(36).slice(2);

    const toast: NotificationPageToast = {
      id,
      styleClasses: dialog.styleClasses,
      title: dialog.title,
      message: dialog.message,
      linger: typeof dialog.linger !== 'undefined' ? dialog.linger : 7500,
      top: toasts.length * 8,
    };

    dispatch(notificationPageSlice.actions.add(toast));

    if (typeof toast.linger !== 'undefined' && toast.linger !== 0) {
      setTimeout(() => {
        remove(toast.id);
      }, toast.linger);
    }
  };

  const remove = (id: string): void => {
    dispatch(notificationPageSlice.actions.remove(id));
  };

  return {
    add,
    remove,
    toasts,
  };
};
