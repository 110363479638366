import React from 'react';
import { IconStyles, IconTemplateProps } from '../';

export const IconLensesHydraBoostPlusTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <img src="/images/icons/lenses-hydra-boost-plus-icon-2.svg" />
    </IconStyles>
  );
};
