import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const authedSSEReducer = (
  state: CustomerState,
  action: PayloadAction<boolean>
) => {
  state.authedSSE = action.payload;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
