import { PlanListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CatalogPlanList } from '@hiwaldo/sdk/interfaces';

export const updatePlanListReducer = (
  state: PlanListState,
  action: PayloadAction<CatalogPlanList>
) => {
  state.loading = false;
  state.loaded = true;
  state.initialized = true;
  state.list = action.payload;
};
