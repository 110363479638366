import { appStorage, AppStorageKey } from '../../../../../helpers';
import { JobState } from '../state';

export const resetJobReducer = (state: JobState) => {
  state.sent = false;
  state.success = false;
  state.loading = false;
  state.error = undefined;

  appStorage.set(AppStorageKey.RENEWAL_JOB, state);
};
