import {
  FunnelProductTypeOption,
  FunnelProductTypeTrial,
  FunnelProductTypeOTP,
  FunnelProductTypeLens,
} from '../../../../components';
import { AppStorageKey, appStorage } from '../../../../helpers';

export type FunnelReferrer = 'email';

export interface FunnelState {
  productType: FunnelProductTypeOption | null;
  productLensType: FunnelProductTypeLens | null;
  productTrialType: FunnelProductTypeTrial | null;
  productOTPType: FunnelProductTypeOTP | null;
  leftEyePower: string | null;
  rightEyePower: string | null;
  hasLenses: boolean;
  hasTrial: boolean;
  hasBundle: boolean;
  hasBothEyes: boolean;
  hasDoctor: boolean;
  hasPrescription: boolean;
  referrer: FunnelReferrer | null;
}

export const initialFunnelState: FunnelState = appStorage.get(
  AppStorageKey.FUNNEL_STATE,
  {
    productType: null,
    productLensType: null,
    productTrialType: null,
    productOTPType: null,
    leftEyePower: null,
    rightEyePower: null,
    hasLenses: false,
    hasTrial: false,
    hasBundle: false,
    hasBothEyes: false,
    hasDoctor: false,
    hasPrescription: false,
    referrer: null,
  }
) as FunnelState;
