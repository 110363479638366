import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { AiOutlineCheckCircle } from 'react-icons/ai';

export const IconCheckCircleTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <AiOutlineCheckCircle />
    </IconStyles>
  );
};
