import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { FunnelReferrer } from '../../../..';
import { AppStorageKey, appStorage } from '../../../../../helpers';

export const setReferrerReducer = (
  state: FunnelState,
  action: PayloadAction<FunnelReferrer | null>
) => {
  state.referrer = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
