import { appStorage, AppStorageKey } from '../../../../helpers';

export interface NewsletterSignUpState {
  email: string | null;
  hasSignedUp: boolean | null;
  loading: boolean;
  error?: string;
}

export const initialNewsletterSignUpState: NewsletterSignUpState =
  appStorage.get(AppStorageKey.NEWSLETTER_STATE, {
    email: null,
    hasSignedUp: false,
    loading: false,
  }) as NewsletterSignUpState;
