import { createSlice } from '@reduxjs/toolkit';
import { initialPlanListState } from './state';
import { planListReloadReducer } from './reducers/plan-list-reload.reducer';
import { planListErrorReducer } from './reducers/plan-list-error.reducer';
import { loadingPlanListReducer } from './reducers/loading-plan-list.reducer';
import { searchPlanListReducer } from './reducers/search-plan-list.reducer';
import { updatePlanListReducer } from './reducers/update-plan-list.reducer';

export const planListSlice = createSlice({
  name: 'PlanList',
  initialState: initialPlanListState,
  reducers: {
    loading: loadingPlanListReducer,
    search: searchPlanListReducer,
    update: updatePlanListReducer,
    reload: planListReloadReducer,
    error: planListErrorReducer,
  },
});

export const planListReducer = planListSlice.reducer;
