import React from 'react';
import { IconStyles, IconTemplateProps } from '../';

export const IconSelectTickTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <img src="/images/icons/select-tick-icon.svg" />
    </IconStyles>
  );
};
