import { ProductState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const productErrorReducer = (
  state: ProductState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
