import { WindowState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateWindowReducer = (
  state: WindowState,
  action: PayloadAction<WindowState>
) => {
  state = action.payload;

  appStorage.set(AppStorageKey.WINDOW_STATE, state);
};
