import React from 'react';
import { IconStyles, IconTemplateProps } from '../';

export const IconArrowRightTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <img src="/images/icons/submit-arrow.svg" />
    </IconStyles>
  );
};
