import { TrialState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CatalogTrial } from '@hiwaldo/sdk/interfaces';

export const updateTrialReducer = (
  state: TrialState,
  action: PayloadAction<CatalogTrial>
) => {
  state.loading = false;
  state.data = action.payload;
};
