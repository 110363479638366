import { IconProps, IconState } from '../interfaces';

export function useIconEffect(props: IconProps): [IconState] {
  const { styleClasses } = props;

  const classes = styleClasses.join(' ');

  const state: IconState = { classes };

  return [state];
}
