import { ProductListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CatalogProductList } from '@hiwaldo/sdk/interfaces';

export const updateProductListReducer = (
  state: ProductListState,
  action: PayloadAction<CatalogProductList>
) => {
  state.loading = false;
  state.list = action.payload;
};
