import { OrderState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { Order } from '@hiwaldo/sdk/interfaces';
export const orderInvoiceReducer = (
  state: OrderState,
  action: PayloadAction<Order>
) => {
  state.loading = false;
  state.data = action.payload;
};
