import { PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { CustomerState } from '../state';

export const registeredCustomerReducer = (
  state: CustomerState,
  action: PayloadAction<Customer>
) => {
  state.loggedIn = true;
  state.authedId = action.payload.id;
  state.customer = action.payload;
  state.registered = true;

  const leftEye = state.customer.activePrescription?.leftEye || undefined;
  const rightEye = state.customer.activePrescription?.rightEye || undefined;
  state.hasPrescription = !!state.customer.activePrescription;
  state.statusDoctorAuth = state.customer.activePrescription?.status || null;
  state.includesBothEyes = !!(leftEye && rightEye);

  const subscription = state.customer.activeCustomerSubscription
    ? state.customer.activeCustomerSubscription
    : undefined;

  if (subscription) {
    state.hasTrialed = true;

    if (!subscription.nextRenewal) {
      state.hasRenewalDate = false;

      const preliminaryRenewalDate = new Date();
      preliminaryRenewalDate.setTime(
        preliminaryRenewalDate.getTime() + 86400000 * 12
      );

      state.customer.activeCustomerSubscription!.nextRenewal =
        preliminaryRenewalDate.toISOString();
    } else if (subscription?.nextRenewal) {
      state.hasRenewalDate = true;
    }
  }

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
