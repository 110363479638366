import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface AccountResumeFormMembers {
  userFormAnswerId: FormMember;
}

export interface AccountResumeFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: AccountResumeFormMembers;
}

export const cleanAccountResumeFormState: AccountResumeFormState = {
  isValid: true,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    userFormAnswerId: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialAccountResumeFormState = appStorage.get(
  AppStorageKey.FORM_ACCOUNT_RESUME_STATE,
  cleanAccountResumeFormState
) as AccountResumeFormState;

export const accountResumeFormSlice = createSlice({
  name: 'AccountResumeForm',
  initialState: () => initialAccountResumeFormState,
  reducers: {
    update: (
      state: AccountResumeFormState,
      action: PayloadAction<AccountResumeFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_ACCOUNT_RESUME_STATE, state);
    },
  },
});
