import { OrderList } from '@hiwaldo/sdk/interfaces';

export interface OrderListState {
  search: OrderListSearchState;
  list: OrderList;
  loading?: boolean;
  error?: string;
}

export interface OrderListSearchState {
  page: number;
  filter: {
    skip: number;
    take: number;
    where: {
      customerId?: string;
      status?: string;
      search?: string;
    };
  };
}

export const initialOrderListSearchState: OrderListSearchState = {
  page: 0,
  filter: {
    skip: 0,
    take: 12,
    where: {},
  },
};

export const initialOrderListState: OrderListState = {
  search: initialOrderListSearchState,
  list: { rows: 0, data: [] },
  error: '',
  loading: false,
};
