import { combineReducers } from 'redux';

import { accountDataSlice, AccountDataState } from './account.redux';
import { basketDataSlice, BasketDataState } from './basket.redux';
import { catalogDataSlice, CatalogDataState } from './catalog.redux';
import { checkoutDataSlice, CheckoutDataState } from './checkout.redux';
import { customerDataSlice, CustomerDataState } from './customer.redux';
// import { funnelSlice, FunnelState } from './funnel.redux';
// import { renewalSlice, RenewalState } from './renewal.redux';
import { orderDataSlice, OrderDataState } from './order.redux';

export type DataState = {
  account: AccountDataState;
  basket: BasketDataState;
  catalog: CatalogDataState;
  checkout: CheckoutDataState;
  customer: CustomerDataState;
  // funnel: FunnelState;
  // renewal: RenewalState;
  order: OrderDataState;
};

export const initialDataState: DataState = {
  account: accountDataSlice.getInitialState(),
  basket: basketDataSlice.getInitialState(),
  catalog: catalogDataSlice.getInitialState(),
  checkout: checkoutDataSlice.getInitialState(),
  customer: customerDataSlice.getInitialState(),
  // funnel: funnelSlice.getInitialState(),
  // renewal: renewalSlice.getInitialState(),
  order: orderDataSlice.getInitialState(),
};

export const dataReducers = combineReducers({
  account: accountDataSlice.reducer,
  basket: basketDataSlice.reducer,
  catalog: catalogDataSlice.reducer,
  checkout: checkoutDataSlice.reducer,
  customer: customerDataSlice.reducer,
  // funnel: funnelSlice.reducer,
  // renewal: renewalSlice.reducer,
  order: orderDataSlice.reducer,
});

export * from './account.redux';
export * from './basket.redux';
export * from './catalog.redux';
export * from './checkout.redux';
export * from './customer.redux';
// export * from './funnel.redux';
// export * from './renewal.redux';
export * from './order.redux';
