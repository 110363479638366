import { createSlice } from '@reduxjs/toolkit';
import { updateNewsletterSignUpReducer } from './reducers/updateNewsletterSignUp.reducer';
import { initialNewsletterSignUpState } from './state';

export const newsletterSignUpSlice = createSlice({
  name: 'NewsletterSignUp',
  initialState: initialNewsletterSignUpState,
  reducers: {
    updateNewsletterSignUp: updateNewsletterSignUpReducer,
  },
});

export const newsletterSignUpReducer = newsletterSignUpSlice.reducer;
