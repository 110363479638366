import { createSlice } from '@reduxjs/toolkit';
import { initialProductListState } from './state';
import { productListErrorReducer } from './reducers/product-list-error.reducer';
import { loadingProductListReducer } from './reducers/loading-product-list.reducer';
import { updateProductListReducer } from './reducers/update-product-list.reducer';

export const productListSlice = createSlice({
  name: 'ProductList',
  initialState: initialProductListState,
  reducers: {
    loading: loadingProductListReducer,
    update: updateProductListReducer,
    error: productListErrorReducer,
  },
});

export const productListReducer = productListSlice.reducer;
