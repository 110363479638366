import { combineReducers } from 'redux';

import {
  geolocationPageSlice,
  GeolocationPageState,
} from './geolocation.redux';

import { gtmPageSlice, GTMPageState } from './gtm.redux';
import { newsletterPageSlice, NewsletterPageState } from './newsletter.redux';
import { menuPageSlice, MenuPageState } from './menu.redux';
import {
  notificationPageSlice,
  NotificationPageState,
} from './notification.redux';
import { policyPageSlice, PolicyPageState } from './policy.redux';
import { welcomePageSlice, WelcomePageState } from './welcome.redux';

export type PageState = {
  geolocation: GeolocationPageState;
  gtm: GTMPageState;
  menu: MenuPageState;
  newsletter: NewsletterPageState;
  notification: NotificationPageState;
  policy: PolicyPageState;
  welcome: WelcomePageState;
};

export const initialPageState: PageState = {
  geolocation: geolocationPageSlice.getInitialState(),
  gtm: gtmPageSlice.getInitialState(),
  menu: menuPageSlice.getInitialState(),
  newsletter: newsletterPageSlice.getInitialState(),
  notification: notificationPageSlice.getInitialState(),
  policy: policyPageSlice.getInitialState(),
  welcome: welcomePageSlice.getInitialState(),
};

export const pageReducers = combineReducers({
  geolocation: geolocationPageSlice.reducer,
  gtm: gtmPageSlice.reducer,
  menu: menuPageSlice.reducer,
  newsletter: newsletterPageSlice.reducer,
  notification: notificationPageSlice.reducer,
  policy: policyPageSlice.reducer,
  welcome: welcomePageSlice.reducer,
});

export * from './geolocation.redux';
export * from './gtm.redux';
export * from './menu.redux';
export * from './newsletter.redux';
export * from './notification.redux';
export * from './policy.redux';
export * from './welcome.redux';
