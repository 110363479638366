import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { FunnelProductTypeOTP } from '../../../../../components';
import { AppStorageKey, appStorage } from '../../../../../helpers';

export const setProductOTPTypeReducer = (
  state: FunnelState,
  action: PayloadAction<FunnelProductTypeOTP>
) => {
  state.productOTPType = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
