import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { CustomerAccountState } from '../state';

export const loadedAccountReducer = (
  state: CustomerAccountState,
  action: PayloadAction<CustomerAccountState>
) => {
  state.billing = action.payload.billing;
  state.delivery = action.payload.delivery;
  state.personal = action.payload.personal;
  state.orders = action.payload.orders;
  state.payment = action.payload.payment;
  state.subscription.cost = action.payload.subscription.cost;
  state.subscription.renewal = action.payload.subscription.renewal;
  state.subscription.plan = action.payload.subscription.plan;
  state.subscription.prescription = action.payload.subscription.prescription;

  appStorage.set(AppStorageKey.ACCOUNT_STATE, state);
};
