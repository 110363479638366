import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { BsChevronUp } from 'react-icons/bs';

export const IconChevronUpTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <BsChevronUp />
    </IconStyles>
  );
};
