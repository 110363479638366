import { combineReducers } from 'redux';
import {
  customerSliceReducer,
  CustomerState,
  initialCustomerState,
} from './customer';
import {
  customerAccountSliceReducer,
  CustomerAccountState,
  initialCustomerAccountState,
} from './account';

export * from './account';
export * from './customer';

export type CustomersState = {
  account: CustomerAccountState;
  customer: CustomerState;
};

export const initialCustomersState: CustomersState = {
  account: initialCustomerAccountState,
  customer: initialCustomerState,
};

export const customersReducers = combineReducers({
  account: customerAccountSliceReducer,
  customer: customerSliceReducer,
});
