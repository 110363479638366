import { createSlice } from '@reduxjs/toolkit';
import { updateJobReducer } from './reducers/update-job.reducer';
import { resetJobReducer } from './reducers/reset-job.reducer';
import { initialJobState } from './state';

export const jobSlice = createSlice({
  name: 'Job',
  initialState: initialJobState,
  reducers: {
    update: updateJobReducer,
    reset: resetJobReducer,
  },
});

export const jobReducer = jobSlice.reducer;
