import { UserForm } from '@hiwaldo/sdk/interfaces';

export interface UserFormState {
  data: UserForm | null;
  loading?: boolean;
  error?: string;
}

export const initialUserFormState: UserFormState = {
  data: null,
  error: '',
  loading: false,
};
