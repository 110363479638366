import { CatalogProductType } from '@hiwaldo/sdk/enums';
import { CatalogProductList } from '@hiwaldo/sdk/interfaces';

export interface ProductListState {
  search: ProductListSearchState;
  list: CatalogProductList;
  loading?: boolean;
  error?: string;
}

export interface ProductListSearchState {
  page: number;
  filter: {
    skip: number;
    take: number;
    where: {
      id?: string;
      title?: string;
      type?: CatalogProductType;
      search?: string;
    };
    // order: { [key: string]: "asc" | "desc" };
  };
}

export const initialProductListSearchState: ProductListSearchState = {
  page: 0,
  filter: {
    skip: 0,
    take: 12,
    where: {},
    // order: { id: "desc" },
  },
};

export const initialProductListState: ProductListState = {
  search: initialProductListSearchState,
  list: { rows: 0, data: [] },
  error: '',
  loading: false,
};
