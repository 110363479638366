import { combineReducers } from 'redux';

import {
  AccountFormState,
  initialAccountFormState,
  accountFormReducers,
} from './account';

import {
  BasketFormState,
  initialBasketFormState,
  basketFormReducers,
} from './basket';

import {
  CaptureFormState,
  initialCaptureFormState,
  captureFormReducers,
} from './capture';

import {
  CheckoutFormState,
  initialCheckoutFormState,
  checkoutFormReducers,
} from './checkout';

import {
  CustomerFormState,
  initialCustomerFormState,
  customerFormReducers,
} from './customer';

import {
  SurveysFormState,
  initialSurveysFormState,
  surveysFormReducers,
} from './surveys';

export * from './account';
export * from './basket';
export * from './capture';
export * from './checkout';
export * from './customer';
export * from './surveys';

export type FormState = {
  account: AccountFormState;
  basket: BasketFormState;
  capture: CaptureFormState;
  checkout: CheckoutFormState;
  customer: CustomerFormState;
  surveys: SurveysFormState;
};

export const initialFormState: FormState = {
  account: initialAccountFormState,
  basket: initialBasketFormState,
  capture: initialCaptureFormState,
  checkout: initialCheckoutFormState,
  customer: initialCustomerFormState,
  surveys: initialSurveysFormState,
};

export const formReducers = combineReducers({
  account: accountFormReducers,
  basket: basketFormReducers,
  capture: captureFormReducers,
  checkout: checkoutFormReducers,
  customer: customerFormReducers,
  surveys: surveysFormReducers,
});
