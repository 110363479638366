import { CartState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const showBasketReducer = (state: CartState) => {
  state.basketOpen = true;

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
