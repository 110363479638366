import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { RxCross1 } from 'react-icons/rx';

export const IconCrossTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <RxCross1 />
    </IconStyles>
  );
};
