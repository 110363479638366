import { Clinic, ClinicState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const updateClinicReducer = (
  state: ClinicState,
  action: PayloadAction<Clinic>
) => {
  state.loading = false;
  state.data = action.payload;
};
