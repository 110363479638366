import { Cart } from '@hiwaldo/sdk/interfaces';
import { PaymentMethods } from '../../../../data/subscription/payment-methods';
import { AppStorageKey, appStorage } from '../../../../helpers';

export interface CartState {
  cart: Cart | null;
  countItems: number;
  countProductVariants: number;
  countBundles: number;
  countTrials: number;
  includesLenses: boolean;
  includesTrial: boolean;
  includesBundle: boolean;
  includesBothEyes: boolean;
  includesBLG: boolean;
  includesDrops: boolean;
  includesDoctor: boolean;
  includesPrescription: boolean;
  includesTax: boolean;
  basketOpen: boolean;
  paymentGateway: PaymentMethods | null;
  paypal: {
    paypalToken: string | null;
    paypalStatus: string | null;
  };
}

export const initialCartState: CartState = appStorage.get(
  AppStorageKey.CART_STATE,
  {
    cart: null,
    countItems: 0,
    countProductVariants: 0,
    countBundles: 0,
    countTrials: 0,
    includesLenses: false,
    includesTrial: false,
    includesBundle: false,
    includesBLG: false,
    includesDrops: false,
    includesBothEyes: false,
    includesDoctor: false,
    includesPrescription: false,
    includesTax: false,
    basketOpen: false,
    paymentGateway: null,
    paypal: {
      paypalToken: '',
      paypalStatus: '',
    },
  }
) as CartState;
