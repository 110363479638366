import { createSlice } from '@reduxjs/toolkit';
import { loadingUserFormReducer } from './reducers/loading-user-form.reducer';
import { updateUserFormReducer } from './reducers/update-user-form.reducer';
import { userFormErrorReducer } from './reducers/user-form-error.reducer';
import { initialUserFormState } from './state';

export const userFormSlice = createSlice({
  name: 'UserForm',
  initialState: initialUserFormState,
  reducers: {
    loading: loadingUserFormReducer,
    update: updateUserFormReducer,
    error: userFormErrorReducer,
  },
});

export const userFormReducer = userFormSlice.reducer;
