export const envApolloUrls = {
  prod: {
    gateway: 'https://service-gateway.prod.eu-west-2.waldocloud.com',
    external: 'https://service-external.prod.eu-west-2.waldocloud.com',
  },
  uat: {
    gateway: 'https://service-gateway.uat.eu-west-2.waldocloud.com',
    external: 'https://service-external.uat.eu-west-2.waldocloud.com',
  },
  staging: {
    gateway: 'https://service-gateway.uat.eu-west-2.waldocloud.com',
    external: 'https://service-external.uat.eu-west-2.waldocloud.com',
  },
  local: {
    gateway: 'https://service-gateway.uat.eu-west-2.waldocloud.com',
    external: 'https://service-external.uat.eu-west-2.waldocloud.com',
  },
};
