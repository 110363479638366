import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface AccountCancelFormMembers {
  userFormAnswerId: FormMember;
}

export interface AccountCancelFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: AccountCancelFormMembers;
}

export const cleanAccountCancelFormState: AccountCancelFormState = {
  isValid: true,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    userFormAnswerId: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialAccountCancelFormState = appStorage.get(
  AppStorageKey.FORM_ACCOUNT_CANCEL_STATE,
  cleanAccountCancelFormState
) as AccountCancelFormState;

export const accountCancelFormSlice = createSlice({
  name: 'AccountCancelForm',
  initialState: () => initialAccountCancelFormState,
  reducers: {
    update: (
      state: AccountCancelFormState,
      action: PayloadAction<AccountCancelFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_ACCOUNT_CANCEL_STATE, state);
    },
  },
});
