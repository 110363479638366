import { appStorage, AppStorageKey } from '../../../../helpers';

export interface CustomerAccountState {
  personal: boolean;
  delivery: boolean;
  billing: boolean;
  payment: boolean;
  orders: boolean;
  subscription: {
    plan: boolean;
    renewal: boolean;
    prescription: boolean;
    cost: boolean;
  };
}

export const initialCustomerAccountState: CustomerAccountState = appStorage.get(
  AppStorageKey.ACCOUNT_STATE,
  {
    personal: false,
    delivery: false,
    billing: false,
    payment: false,
    orders: false,
    subscription: {
      plan: false,
      renewal: false,
      prescription: false,
      cost: false,
    },
  }
) as CustomerAccountState;
