import { ProductListSearchState, ProductListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const loadingProductListReducer = (
  state: ProductListState,
  action?: PayloadAction<ProductListSearchState>
) => {
  state.loading = true;

  if (action) {
    state.search = action.payload;
  }
};
