import { appStorage, AppStorageKey } from '../../../../helpers';

export interface CheckoutRegisterFormData {
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface CheckoutAddressFormData {
  matchAddress: boolean;
  billingAddress: {
    firstName: string;
    lastName: string;
    company: string | undefined;
    line1: string;
    line2: string | undefined;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
  };
  shippingAddress: {
    firstName: string;
    lastName: string;
    company: string | undefined;
    line1: string;
    line2: string | undefined;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
  };
}

export interface CheckoutFormData {
  registration: CheckoutRegisterFormData;
  address: CheckoutAddressFormData;
}

export interface CheckoutPageState {
  completedRegistration: boolean;
  completedShipping: boolean;
  completedBilling: boolean;
  completedPayment: boolean;
  completedForm: boolean;
  openRegistration: boolean;
  openLogin: boolean;
  openShipping: boolean;
  openBilling: boolean;
  openSummary: boolean;
  openPayment: boolean;
  validRegistration: boolean;
  validShipping: boolean;
  validBilling: boolean;
  validPayment: boolean;
  validForm: boolean;
}

export interface CheckoutState {
  formData: CheckoutFormData;
  pageState: CheckoutPageState;
}

export const fallbackCheckoutState: CheckoutState = {
  formData: {
    registration: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    address: {
      matchAddress: true,
      billingAddress: {
        firstName: '',
        lastName: '',
        company: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        phone: '',
      },
      shippingAddress: {
        firstName: '',
        lastName: '',
        company: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        phone: '',
      },
    },
  },
  pageState: {
    completedRegistration: false,
    completedShipping: false,
    completedBilling: false,
    completedPayment: false,
    completedForm: false,
    openRegistration: true,
    openLogin: false,
    openShipping: false,
    openBilling: false,
    openSummary: false,
    openPayment: false,
    validRegistration: false,
    validShipping: false,
    validBilling: false,
    validPayment: false,
    validForm: false,
  },
};

export const initialCheckoutState: CheckoutState = appStorage.get(
  AppStorageKey.CHECKOUT_STATE,
  fallbackCheckoutState
) as CheckoutState;
