import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { IoIosContact } from 'react-icons/io';

export const IconContactTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <IoIosContact />
    </IconStyles>
  );
};
