import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormMember } from '../../../interfaces';

export interface CustomerLoginFormMembers {
  email: FormMember;
  password: FormMember;
}

export interface CustomerLoginFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: CustomerLoginFormMembers;
}

export const cleanCustomerLoginFormState: CustomerLoginFormState = {
  isValid: false,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    email: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    password: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const updateCustomerLoginFormState = (
  state: CustomerLoginFormState,
  action: PayloadAction<CustomerLoginFormState>
) => {
  state.isValid = action.payload.isValid;
  state.isTouched = action.payload.isTouched;
  state.isLoading = action.payload.isLoading;
  state.isSubmitted = action.payload.isSubmitted;
  state.members = action.payload.members;
};

export const initialCustomerLoginFormState = cleanCustomerLoginFormState;

export const customerLoginFormSlice = createSlice({
  name: 'CustomerLoginForm',
  initialState: initialCustomerLoginFormState,
  reducers: {
    update: updateCustomerLoginFormState,
  },
});
