import { combineReducers } from 'redux';

import {
  basketDiscountFormSlice,
  BasketDiscountFormState,
} from './basket-discount.redux';

export interface BasketFormState {
  discount: BasketDiscountFormState;
}

export const initialBasketFormState: BasketFormState = {
  discount: basketDiscountFormSlice.getInitialState(),
};

export const basketFormReducers = combineReducers({
  discount: basketDiscountFormSlice.reducer,
});

export * from './basket-discount.redux';
