import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

export const IconExclamationReactTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <AiOutlineExclamationCircle />
    </IconStyles>
  );
};
