import { StyledThemeSizing } from '../..';

export const StyledThemeBaseSizing = (): StyledThemeSizing => {
  return {
    paddingXS: 0.8,
    paddingS: 1,
    paddingM: 1.4,
    paddingL: 1.6,
    paddingXL: 2,
    marginXS: 0.8,
    marginS: 1,
    marginM: 1.4,
    marginL: 1.6,
    marginXL: 2,
    borderXS: 0.1,
    borderS: 0.1,
    borderM: 0.1,
    borderL: 0.1,
    borderXL: 0.1,
    radiusXS: 0.4,
    radiusS: 1,
    radiusM: 1.4,
    radiusL: 1.6,
    radiusXL: 2,
    fontXS: 0.8,
    fontS: 1,
    fontM: 1.4,
    fontL: 1.6,
    fontXL: 2,
    spanXS: 0,
    spanS: 0.4,
    spanM: 0.9,
    spanL: 1.2,
    spanXL: 1.6,
  };
};
