import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

import { ShippingAddress, Customer } from '@hiwaldo/sdk/interfaces';

export const createCustomerShippingAddressReducer = (
  state: CustomerState,
  action: PayloadAction<ShippingAddress>
) => {
  (state.customer as Customer).shippingAddress = action.payload;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
