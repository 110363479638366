import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const loginCustomerReducer = (
  state: CustomerState,
  action: PayloadAction<string>
) => {
  appStorage.set(AppStorageKey.AUTH_TOKEN, action.payload);
};
