import { createSlice } from '@reduxjs/toolkit';
import { updateCheckoutPageReducer } from './reducers/update-checkout-page.reducer';
import { updateCheckoutRegistrationFormReducer } from './reducers/update-checkout-registration.reducer';
import { updateCheckoutAddressReducer } from './reducers/update-checkout-address.reducer';
import { clearCheckoutReducer } from './reducers/clear-checkout.reducer';
import { initialCheckoutState } from './state';

export const checkoutSlice = createSlice({
  name: 'Checkout',
  initialState: initialCheckoutState,
  reducers: {
    updatePage: updateCheckoutPageReducer,
    updateRegistration: updateCheckoutRegistrationFormReducer,
    updateAddress: updateCheckoutAddressReducer,
    clearCheckout: clearCheckoutReducer,
  },
});

export const checkoutReducer = checkoutSlice.reducer;
