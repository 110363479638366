import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { appStorage, AppStorageKey } from '../../../helpers';
import { Region } from '@hiwaldo/sdk';

export type CustomerAccountType =
  | 'UK-ACTIVE'
  | 'UK-PENDING'
  | 'UK-INACTIVE'
  | 'UK-OTP'
  | 'EU-ACTIVE'
  | 'EU-PENDING'
  | 'EU-INACTIVE'
  | 'EU-OTP'
  | 'SE-ACTIVE'
  | 'SE-PENDING'
  | 'SE-INACTIVE'
  | 'SE-OTP'
  | 'US-ACTIVE'
  | 'US-INACTIVE'
  | 'US-FAILED'
  | 'US-OTP'
  | 'US-PENDING';

export interface CustomerPayPalConfig {
  paypalToken: string | null;
  paypalStatus: string | null;
}

export interface CustomerDataState {
  loading: boolean;
  loggedIn: boolean;
  allowSSE: boolean;
  isRegistered: boolean;
  isConverted: boolean;
  isVerified: boolean;
  hasResetPassword: boolean;
  customBilling: boolean;
  includesShippingBilling: boolean;
  includesPaymentDetails: boolean;
  includesSubscription: boolean;
  includesPrescription: boolean;
  includesBothEyes: boolean;
  includesDuplicateEyes: boolean;

  customer?: Customer;
  accountType?: CustomerAccountType;
  discountCode?: string;
  authedId?: number;
  error?: string;
  clientSecret?: string;
  paypal?: CustomerPayPalConfig;
  invalidRegion?: Region;
}

export const cleanCustomerDataState: CustomerDataState = {
  loading: false,
  loggedIn: false,
  allowSSE: false,
  isRegistered: false,
  isConverted: false,
  isVerified: false,
  hasResetPassword: false,
  customBilling: false,
  includesShippingBilling: false,
  includesPaymentDetails: false,
  includesSubscription: false,
  includesPrescription: false,
  includesBothEyes: false,
  includesDuplicateEyes: false,
};

export const initialCustomerDataState = appStorage.get(
  AppStorageKey.DATA_CUSTOMER_STATE,
  cleanCustomerDataState
) as CustomerDataState;

export const customerDataSlice = createSlice({
  name: 'CustomerData',
  initialState: initialCustomerDataState,
  reducers: {
    update: (
      state: CustomerDataState,
      action: PayloadAction<CustomerDataState>
    ) => {
      state.loading = action.payload.loading;
      state.loggedIn = action.payload.loggedIn;
      state.allowSSE = action.payload.allowSSE;
      state.isRegistered = action.payload.isRegistered;
      state.isConverted = action.payload.isConverted;
      state.isVerified = action.payload.isVerified;
      state.hasResetPassword = action.payload.hasResetPassword;
      state.includesShippingBilling = action.payload.includesShippingBilling;
      state.includesPaymentDetails = action.payload.includesPaymentDetails;
      state.includesSubscription = action.payload.includesSubscription;
      state.includesPrescription = action.payload.includesPrescription;
      state.includesBothEyes = action.payload.includesBothEyes;
      state.includesDuplicateEyes = action.payload.includesDuplicateEyes;
      state.customer = action.payload.customer;
      state.discountCode = action.payload.discountCode;
      state.authedId = action.payload.authedId;
      state.error = action.payload.error;
      state.clientSecret = action.payload.clientSecret;
      state.paypal = action.payload.paypal;
      state.invalidRegion = action.payload.invalidRegion;
      state.accountType = undefined;

      let status: 'OTP' | 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'FAILED' =
        'ACTIVE';
      let subscription = undefined;

      if (state.customer) {
        if (!state.customer.activeCustomerSubscription) {
          status = 'OTP';
        } else {
          subscription = state.customer.activeCustomerSubscription;

          switch (subscription?.status) {
            case 'PENDING':
              status = 'PENDING';
              break;
            case 'REJECTED':
              status = 'FAILED';
              break;
            case 'CANCELLED':
            case 'PAUSED':
              status = 'INACTIVE';
              break;
          }
        }

        state.accountType =
          `${state.customer.region}-${status}` as CustomerAccountType;
      }

      appStorage.set(AppStorageKey.DATA_CUSTOMER_STATE, state);

      return state;
    },
  },
});
