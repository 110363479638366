import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { Customer } from '@hiwaldo/sdk/interfaces';

export const updateCustomerReducer = (
  state: CustomerState,
  action: PayloadAction<Customer>
) => {
  state.customer = action.payload as Customer;
  state.authedId = action.payload.id;

  const subscription = state.customer.activeCustomerSubscription
    ? state.customer.activeCustomerSubscription
    : undefined;

  if (subscription) {
    state.hasTrialed = true;

    if (!subscription.nextRenewal) {
      state.hasRenewalDate = false;

      const preliminaryRenewalDate = new Date();
      preliminaryRenewalDate.setTime(
        preliminaryRenewalDate.getTime() + 86400000 * 12
      );

      state.customer.activeCustomerSubscription!.nextRenewal =
        preliminaryRenewalDate.toISOString();
    } else if (subscription?.nextRenewal) {
      state.hasRenewalDate = true;
    }
  }

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
