import { createSlice } from '@reduxjs/toolkit';
import { loadingTrialReducer } from './reducers/loading-trial.reducer';
import { updateTrialReducer } from './reducers/update-trial.reducer';
import { trialErrorReducer } from './reducers/trial-error.reducer';
import { initialTrialState } from './state';

export const trialSlice = createSlice({
  name: 'Trial',
  initialState: initialTrialState,
  reducers: {
    loading: loadingTrialReducer,
    update: updateTrialReducer,
    error: trialErrorReducer,
  },
});

export const trialReducer = trialSlice.reducer;
