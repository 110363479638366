import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface CaptureNewsletterFormMembers {
  email: FormMember;
}

export interface CaptureNewsletterFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: CaptureNewsletterFormMembers;
}

export const cleanCaptureNewsletterFormState: CaptureNewsletterFormState = {
  isValid: false,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    email: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialCaptureNewsletterFormState = appStorage.get(
  AppStorageKey.FORM_CAPTURE_NEWSLETTER_STATE,
  cleanCaptureNewsletterFormState
) as CaptureNewsletterFormState;

export const captureNewsletterFormSlice = createSlice({
  name: 'CaptureNewsletterForm',
  initialState: () => initialCaptureNewsletterFormState,
  reducers: {
    update: (
      state: CaptureNewsletterFormState,
      action: PayloadAction<CaptureNewsletterFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_CAPTURE_NEWSLETTER_STATE, state);
    },
  },
});
