import { createSlice } from '@reduxjs/toolkit';
import { loadedAccountReducer } from './reducers/loaded-account.reducer';
import { loadedBillingReducer } from './reducers/loaded-billing.reducer';
import { loadedDeliveryReducer } from './reducers/loaded-delivery.reducer';
import { loadedOrdersReducer } from './reducers/loaded-orders.reducer';
import { loadedPaymentReducer } from './reducers/loaded-payment.reducer';
import { loadedPersonalReducer } from './reducers/loaded-personal.reducer';
import { loadedSubscriptionCostReducer } from './reducers/loaded-subscription-cost.reducer';
import { loadedSubscriptionPlanReducer } from './reducers/loaded-subscription-plan.reducer';
import { loadedSubscriptionPrescriptionReducer } from './reducers/loaded-subscription-prescription.reducer';
import { loadedSubscriptionRenewalReducer } from './reducers/loaded-subscription-renewal.reducer';
import { initialCustomerAccountState } from './state';

export const customerAccountSlice = createSlice({
  name: 'CustomerAccount',
  initialState: initialCustomerAccountState,
  reducers: {
    loadedAccount: loadedAccountReducer,
    loadedBilling: loadedBillingReducer,
    loadedDelivery: loadedDeliveryReducer,
    loadedOrders: loadedOrdersReducer,
    loadedPayment: loadedPaymentReducer,
    loadedPersonal: loadedPersonalReducer,
    loadedSubscriptionCost: loadedSubscriptionCostReducer,
    loadedSubscriptionPlan: loadedSubscriptionPlanReducer,
    loadedSubscriptionPrescription: loadedSubscriptionPrescriptionReducer,
    loadedSubscriptionRenewal: loadedSubscriptionRenewalReducer,
  },
});

export const customerAccountSliceReducer = customerAccountSlice.reducer;
