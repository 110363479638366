import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { BillingAddress } from '@hiwaldo/sdk/interfaces';

export const updateCustomerBillingAddressReducer = (
  state: CustomerState,
  action: PayloadAction<BillingAddress>
) => {
  state.customer!.billingAddress = action.payload;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
