import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { MdSettingsOverscan } from 'react-icons/md';

export const IconSettingsTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <MdSettingsOverscan />
    </IconStyles>
  );
};
