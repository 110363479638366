import { CartState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const hideBasketReducer = (state: CartState) => {
  state.basketOpen = false;

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
