import { createSlice } from '@reduxjs/toolkit';
import { loadingProductVariantReducer } from './reducers/loading-product-variant.reducer';
import { updateProductVariantReducer } from './reducers/update-product-variant.reducer';
import { productVariantErrorReducer } from './reducers/product-variant-error.reducer';
import { initialProductVariantState } from './state';

export const productVariantSlice = createSlice({
  name: 'ProductVariant',
  initialState: initialProductVariantState,
  reducers: {
    loading: loadingProductVariantReducer,
    update: updateProductVariantReducer,
    error: productVariantErrorReducer,
  },
});

export const productVariantReducer = productVariantSlice.reducer;
