import { ChargeState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const chargeErrorReducer = (
  state: ChargeState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
