import { createSlice } from '@reduxjs/toolkit';
import { updateDataLayerReducer } from './reducers/updateDataLayer.reducer';
import { initialGTMState } from './state';

export const gtmPageSlice = createSlice({
  name: 'GTM',
  initialState: initialGTMState,
  reducers: {
    updateDataLayer: updateDataLayerReducer,
  },
});

export const gtmReducer = gtmPageSlice.reducer;
