import { Region } from '@hiwaldo/sdk';
import {
  appStorage,
  AppStorageKey,
  ApplicationEnvironment,
} from '../../../../helpers';

export interface WindowState {
  location: string | undefined;
  route: string | undefined;
  region: Region | undefined;
  environment: ApplicationEnvironment | undefined;
}

export const initialWindowState: WindowState = appStorage.get(
  AppStorageKey.WINDOW_STATE,
  {
    location: undefined,
    route: undefined,
    region: undefined,
    environment: undefined,
  }
) as WindowState;
