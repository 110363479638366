import { createSlice } from '@reduxjs/toolkit';
import { clearGeolocationReducer } from './reducers/clearGeolocation.reducer';
import { updateGeolocationReducer } from './reducers/updateGeolocation.reducer';
import { initialGeolocationState } from './state';

export const geolocationSlice = createSlice({
  name: 'Geolocation',
  initialState: initialGeolocationState,
  reducers: {
    clearGeolocation: clearGeolocationReducer,
    updateGeolocation: updateGeolocationReducer,
  },
});

export const geolocationReducer = geolocationSlice.reducer;
