import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { BsCalendarEvent } from 'react-icons/bs';

export const IconCalendarEventTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <BsCalendarEvent />
    </IconStyles>
  );
};
