import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';

export type CheckoutStep =
  | 'register'
  | 'login'
  | 'shipping'
  | 'review'
  | 'payment'
  | 'welcome';

export type CheckoutPaymentOption =
  | 'stripe'
  | 'paypal'
  | 'google-pay'
  | 'apple-pay';

export interface CheckoutStripeConfig {
  applePay: boolean;
  googlePay: boolean;
}

export interface CheckoutPayPalConfig {
  paypalToken: string | null;
  paypalStatus: string | null;
}

export interface CheckoutDataState {
  completedAuthentication: boolean;
  completedShipping: boolean;
  completedPayment: boolean;
  editAuthentication: boolean;
  editShipping: boolean;
  orderCreated: boolean;
  currentPayment: CheckoutPaymentOption;

  currentStep?: CheckoutStep;
}

export const cleanCheckoutDataState: CheckoutDataState = {
  completedAuthentication: false,
  completedShipping: false,
  completedPayment: false,
  editAuthentication: false,
  editShipping: false,
  orderCreated: false,
  currentPayment: 'stripe',
};

export const initialCheckoutDataState = appStorage.get(
  AppStorageKey.DATA_CHECKOUT_STATE,
  cleanCheckoutDataState
) as CheckoutDataState;

export const checkoutDataSlice = createSlice({
  name: 'CheckoutData',
  initialState: initialCheckoutDataState,
  reducers: {
    update: (
      state: CheckoutDataState,
      action: PayloadAction<CheckoutDataState>
    ) => {
      state.completedAuthentication = action.payload.completedAuthentication;
      state.completedShipping = action.payload.completedShipping;
      state.completedPayment = action.payload.completedPayment;
      state.editAuthentication = action.payload.editAuthentication;
      state.editShipping = action.payload.editShipping;
      state.orderCreated = action.payload.orderCreated;
      state.currentStep = action.payload.currentStep;
      state.currentPayment = action.payload.currentPayment;

      appStorage.set(AppStorageKey.DATA_CHECKOUT_STATE, state);

      return state;
    },
  },
});
