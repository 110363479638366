import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';

export interface PolicyPageState {
  cookies: boolean;
  privacy: boolean;
  terms: boolean;
}

export const cleanPolicyPageState: PolicyPageState = {
  cookies: false,
  privacy: false,
  terms: false,
};

export const initialPolicyPageState = appStorage.get(
  AppStorageKey.PAGE_POLICY_STATE,
  cleanPolicyPageState
) as PolicyPageState;

export const policyPageSlice = createSlice({
  name: 'PolicyPage',
  initialState: initialPolicyPageState,
  reducers: {
    update: (
      state: PolicyPageState,
      action: PayloadAction<PolicyPageState>
    ) => {
      state.cookies = action.payload.cookies;
      state.privacy = action.payload.privacy;
      state.terms = action.payload.terms;

      appStorage.set(AppStorageKey.PAGE_POLICY_STATE, state);

      return state;
    },
  },
});
