import { FaqListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const faqListErrorReducer = (
  state: FaqListState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
