import { ProductState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CatalogProduct } from '@hiwaldo/sdk/interfaces';

export const updateProductReducer = (
  state: ProductState,
  action: PayloadAction<CatalogProduct>
) => {
  state.loading = false;
  state.data = action.payload;
};
