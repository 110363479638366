import { StyledThemePalette } from '../..';

export const StyledThemeDoctorPalette = (): StyledThemePalette => {
  return {
    primary: {
      color: '#0032A0',
      rgba: (alpha: string) => `rgba(0, 50, 160, ${alpha})`,
    },
    primaryBackground: {
      color: '#FFFFFF',
      rgba: (alpha: string) => `rgba(255, 255, 255, ${alpha})`,
    },
    primaryHighlight: {
      color: '#ECBAA8',
      rgba: (alpha: string) => `rgba(236, 186, 168, ${alpha})`,
    },
    primaryButton: {
      color: '#FE5000',
      rgba: (alpha: string) => `rgba(254, 80, 0, ${alpha})`,
    },
    primaryError: {
      color: '#DA291C',
      rgba: (alpha: string) => `rgba(218, 41, 28, ${alpha})`,
    },
    secondary: {
      color: '#0057B7',
      rgba: (alpha: string) => `rgba(0, 87, 183, ${alpha})`,
    },
    secondaryBackground: {
      color: '#ECEFF7',
      rgba: (alpha: string) => `rgba(236, 239, 247, ${alpha})`,
    },
    secondaryHighlight: {
      color: '#E31C79',
      rgba: (alpha: string) => `rgba(227, 28, 121, ${alpha})`,
    },
    complementaryBackground: {
      color: '#DCBFA6',
      rgba: (alpha: string) => `rgba(220, 191, 166, ${alpha})`,
    },
    complementaryHighlight: {
      color: '#D995B4',
      rgba: (alpha: string) => `rgba(217, 149, 180, ${alpha})`,
    },
    productBackground: {
      color: '#F8F8F8',
      rgba: (alpha: string) => `rgba(248, 248, 248, ${alpha})`,
    },
    tertiaryBackground: {
      color: '#EAE6DD',
      rgba: (alpha: string) => `rgba(234, 230, 221, ${alpha})`,
    },
    invalidBackground: {
      color: '#FE5000',
      rgba: (alpha: string) => `rgba(254, 80, 0, ${alpha})`,
    },
    successBackground: {
      color: '#D0EEE8',
      rgba: (alpha: string) => `rgba(208, 238, 232, ${alpha})`,
    },
    accountBackground: {
      color: '#D5DEF1',
      rgba: (alpha: string) => `rgba(213, 222, 241, ${alpha})`,
    },
  };
};
