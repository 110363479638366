import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { concatPagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { from } from 'apollo-boost';
import { storedAuthToken } from '../utils';
import * as environment from '../../environment';

let apolloClient: ApolloClient<any>;

function createApolloClient(gateway: string): ApolloClient<any> {
  const httpLink = createHttpLink({
    uri: gateway, // Server URL (must be absolute)
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = storedAuthToken(true);

    return token && token.length ? { headers: { ...headers, token } } : headers;
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) => {
        if (message.includes('Authentication')) {
          localStorage.removeItem('AuthToken');
          localStorage.removeItem('AuthenticationState');
          window.location.href = '/login';
        }
      });
  });

  const apolloLink = from([errorLink as any, httpLink]);

  return new ApolloClient({
    ssrMode: true,
    link: authLink.concat(apolloLink as any as ApolloLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState: any = null): any {
  const application: string =
    process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV!;

  const gateway =
    environment.envApolloUrls[
      application as 'prod' | 'uat' | 'staging' | 'local'
    ].gateway;

  const _apolloClient = apolloClient ?? createApolloClient(gateway);

  // Get existing cache, loaded during client side data fetching
  const existingCache = _apolloClient.extract();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState && existingCache) {
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
