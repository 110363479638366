import { createSlice } from '@reduxjs/toolkit';
import { initialFunnelState } from './state';
import { clearFunnelReducer } from './reducers/clear-funnel.reducer';
import { setLeftEyePowerReducer } from './reducers/setLeftEyePower.reducer';
import { setRightEyePowerReducer } from './reducers/setRightEyePower.reducer';
import { setReferrerReducer } from './reducers/setReferrer.reducer';
import { setHasLensesReducer } from './reducers/setHasLenses.reducer';
import { setHasTrialReducer } from './reducers/setHasTrial.reducer';
import { setHasBundleReducer } from './reducers/setHasBundle.reducer';
import { setHasBothEyesReducer } from './reducers/setHasBothEyes.reducer';
import { setHasDoctorReducer } from './reducers/setHasDoctor.reducer';
import { setHasPrescriptionReducer } from './reducers/setHasPrescription.reducer';
import { setProductTypeReducer } from './reducers/setProductType.reducer';
import { setProductTrialTypeReducer } from './reducers/setProductTrialType.reducer';
import { setProductLensTypeReducer } from './reducers/setProductLensType.reducer';
import { setProductOTPTypeReducer } from './reducers/setProductOTPType.reducer';

export const funnelSlice = createSlice({
  name: 'Funnel',
  initialState: initialFunnelState,
  reducers: {
    clear: clearFunnelReducer,
    setLeftEyePower: setLeftEyePowerReducer,
    setRightEyePower: setRightEyePowerReducer,
    setReferrer: setReferrerReducer,
    setHasLenses: setHasLensesReducer,
    setHasTrial: setHasTrialReducer,
    setHasBundle: setHasBundleReducer,
    setHasBothEyes: setHasBothEyesReducer,
    setHasDoctor: setHasDoctorReducer,
    setHasPrescription: setHasPrescriptionReducer,
    setProductType: setProductTypeReducer,
    setProductLensType: setProductLensTypeReducer,
    setProductTrialType: setProductTrialTypeReducer,
    setProductOTPType: setProductOTPTypeReducer,
  },
});

export const funnelReducer = funnelSlice.reducer;
