import { AnimatePresence } from 'framer-motion';
import { useToastEffect, ToastDefaultTemplate, ToastProps } from '../../..';

export const ToastProvider = (props: ToastProps) => {
  const [state, handlers] = useToastEffect();

  return (
    <AnimatePresence>
      {state.toasts.map((toast, index) => {
        let template;
        switch (toast.template) {
          case 'toastDefault':
          default:
            template = ToastDefaultTemplate({
              toast,
              index,
              onClose: handlers.onClose,
            });
            break;
        }

        return template;
      })}
      {props.children}
    </AnimatePresence>
  );
};
