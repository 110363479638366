import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { UserFormQuestion } from '@hiwaldo/sdk/interfaces';
import { CreateUserFormAnswerInput } from '../../../../server';

export interface SurveyWelcomeFormFlowStep {
  question: UserFormQuestion;
  answer: CreateUserFormAnswerInput;
}

export interface SurveyWelcomeFormFlowState {
  currentStep?: number;
  steps?: {
    [key: string]: SurveyWelcomeFormFlowStep;
  };
}

export const cleanSurveyWelcomeFormFlowState: SurveyWelcomeFormFlowState = {};

export const initialSurveyWelcomeFormFlowState = appStorage.get(
  AppStorageKey.FORM_SURVEY_WELCOME_FLOW_STATE,
  cleanSurveyWelcomeFormFlowState
) as SurveyWelcomeFormFlowState;

export const surveyWelcomeFormFlowSlice = createSlice({
  name: 'SurveyWelcomeFormFlow',
  initialState: initialSurveyWelcomeFormFlowState,
  reducers: {
    update: (
      state: SurveyWelcomeFormFlowState,
      action: PayloadAction<SurveyWelcomeFormFlowState>
    ) => {
      state.currentStep = action.payload.currentStep;
      state.steps = action.payload.steps;

      appStorage.set(AppStorageKey.FORM_SURVEY_WELCOME_FLOW_STATE, state);

      return state;
    },
  },
});
