import { createSlice } from '@reduxjs/toolkit';
import { loadingDiscountReducer } from './reducers/loading-discount.reducer';
import { updateDiscountReducer } from './reducers/update-discount.reducer';
import { clearDiscountReducer } from './reducers/clear-discount.reducer';
import { discountErrorReducer } from './reducers/discount-error.reducer';
import { initialDiscountState } from './state';

export const discountSlice = createSlice({
  name: 'Discount',
  initialState: initialDiscountState,
  reducers: {
    loading: loadingDiscountReducer,
    update: updateDiscountReducer,
    clear: clearDiscountReducer,
    error: discountErrorReducer,
  },
});

export const discountReducer = discountSlice.reducer;
