import React from 'react';
import { motion } from 'framer-motion';
import { ToastTemplateProps } from '../interfaces';
import { ToastStyles } from '../styles';
import { IconElement } from '../../../elements';

export const ToastDefaultTemplate = (props: ToastTemplateProps) => {
  return (
    <motion.div
      key={props.index}
      style={{
        position: 'fixed',
        top: `${props.index * 8}rem`,
        height: '7.5rem',
        width: '20rem',
        zIndex: 2000,
      }}
      initial={{ opacity: 0, x: -300 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -300 }}
      transition={{
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.5,
      }}
    >
      <ToastStyles
        data-cy="toast-provider"
        id={props.toast.id}
        className={props.toast.styleClasses.join(' ')}
      >
        <div className="toast-heading">
          <div className="toast-title">
            <span className="toast-title-icon">
              <IconElement template="iconCheck" styleClasses={['iconBase']} />
            </span>
            <span className="toast-title-text">{props.toast.title}</span>
          </div>
          <div className="toast-close">
            <span onClick={() => props.onClose(props.toast.id)}>
              <IconElement template="iconX" styleClasses={['iconBase']} />
            </span>
          </div>
        </div>
        <div className="toast-content">
          <p>{props.toast.message}</p>
        </div>
      </ToastStyles>
    </motion.div>
  );
};
