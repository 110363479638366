import { StyledTheme, StyledThemePalette } from '../..';
import { StyledThemeBaseGlobals } from './styled-theme-base-globals.theme';
import { StyledThemeBasePalette } from './styled-theme-base-palette.theme';
import { StyledThemeBaseSizing } from './styled-theme-base-sizing.theme';
import { StyledThemeBaseTypography } from './styled-theme-base-typography.theme';

export const StyledThemeBase = (palette?: StyledThemePalette): StyledTheme => {
  if (!palette) palette = StyledThemeBasePalette();

  return {
    palette,
    sizing: StyledThemeBaseSizing(),
    typography: StyledThemeBaseTypography(),
    globals: StyledThemeBaseGlobals,
  };
};
