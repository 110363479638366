import { FaqState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { Faq } from '@hiwaldo/sdk/interfaces';

export const updateFaqReducer = (
  state: FaqState,
  action: PayloadAction<Faq>
) => {
  state.loading = false;
  state.data = action.payload;
};
