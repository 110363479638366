import { ForecastState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const forecastErrorReducer = (
  state: ForecastState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
