import { FaqState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const faqErrorReducer = (
  state: FaqState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
