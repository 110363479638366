import { AppStorageKey, appStorage } from '../../../../../helpers';
import { FunnelState } from '../state';

export const clearFunnelReducer = (state: FunnelState) => {
  state.productType = null;
  state.productLensType = null;
  state.productTrialType = null;
  state.productOTPType = null;
  state.leftEyePower = null;
  state.rightEyePower = null;
  state.hasLenses = false;
  state.hasTrial = false;
  state.hasBundle = false;
  state.hasBothEyes = false;
  state.hasDoctor = false;
  state.hasPrescription = false;
  state.referrer = null;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
