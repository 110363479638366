import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { RxHamburgerMenu } from 'react-icons/rx';

export const IconHamburgerTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <RxHamburgerMenu />
    </IconStyles>
  );
};
