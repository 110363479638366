import { TrialState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const trialErrorReducer = (
  state: TrialState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
