import { ClinicListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const clinicListErrorReducer = (
  state: ClinicListState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
