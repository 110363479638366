import { ProductVariantState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CatalogProductVariant } from '@hiwaldo/sdk/interfaces';

export const updateProductVariantReducer = (
  state: ProductVariantState,
  action: PayloadAction<CatalogProductVariant>
) => {
  state.loading = false;
  state.data = action.payload;
};
