import { UserFormAnswerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserFormAnswer } from '@hiwaldo/sdk/interfaces';

export const createCustomerUserFormAnswerReducer = (
  state: UserFormAnswerState,
  action: PayloadAction<UserFormAnswer>
) => {
  state.data = action.payload;
};
