import { CartState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { CartFetched } from '@hiwaldo/sdk/interfaces/order/cart-fetched.interface';

export const setFetchedClientSecretReducer = (
  state: CartState,
  action: PayloadAction<CartFetched>
) => {
  const cart = state.cart as Cart;
  cart.fetched = action.payload;
  state.cart = cart;

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
