import { appStorage, AppStorageKey } from '../../../../helpers';

export interface JobState {
  sent: number | false;
  available: boolean;
  success: boolean;
  loading: boolean;
  error?: string;
}

export const initialJobState: JobState = appStorage.get(
  AppStorageKey.RENEWAL_JOB,
  {
    sent: false,
    available: true,
    success: false,
    loading: false,
  }
) as JobState;
