import { GTMState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const updateDataLayerReducer = (
  state: GTMState,
  action: PayloadAction<any>
) => {
  state.dataLayer.push({ ecommerce: null });
  state.dataLayer.push(action.payload);

  if ((window as any)?.dataLayer) {
    (window as any).dataLayer.push(action.payload);
  }
};
