import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { BsChevronDown } from 'react-icons/bs';

export const IconChevronDownTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <BsChevronDown />
    </IconStyles>
  );
};
