import { CatalogProductVariant } from '@hiwaldo/sdk/interfaces';

export interface ProductVariantState {
  data: CatalogProductVariant | null;
  loading?: boolean;
  error?: string;
}

export const initialProductVariantState: ProductVariantState = {
  data: null,
  error: '',
  loading: false,
};
