import { FaqListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
// import { FaqList } from '@hiwaldo/sdk/interfaces';

export const updateFaqListReducer = (
  state: FaqListState,
  action: PayloadAction<any>
) => {
  state.loading = false;
  state.list = action.payload;
};
