import { PayloadAction } from '@reduxjs/toolkit';
import { OrderListState } from '../state';

export const orderListErrorReducer = (
  state: OrderListState,
  action: PayloadAction<string>
) => {
  state.loading = false;
  state.error = action.payload;
};
