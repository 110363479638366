import { AppStorageKey, appStorage } from '../../../../../helpers';
import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const setHasBothEyesReducer = (
  state: FunnelState,
  action: PayloadAction<boolean>
) => {
  state.hasBothEyes = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
