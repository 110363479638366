import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { CustomerState } from '../state';

export const setPaypalStatusReducer = (
  state: CustomerState,
  action: PayloadAction<string>
) => {
  state.paypal.paypalStatus = action.payload;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
