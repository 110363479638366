import { StyledTheme, StyledThemePalette } from '../..';
import { StyledThemeBase } from '../base';
import { StyledThemeDoctorPalette } from './styled-theme-doctor-palette.theme';

export const StyledThemeDoctor = (
  palette?: StyledThemePalette
): StyledTheme => {
  if (!palette) palette = StyledThemeDoctorPalette();

  return {
    ...StyledThemeBase(palette),
  };
};
