import { createSlice } from '@reduxjs/toolkit';
import { authedCustomerReducer } from './reducers/authed-customer.reducer';
import { authedSSEReducer } from './reducers/authed-sse.reducer';
import { clearCustomerReducer } from './reducers/clear-customer.reducer';
import { registeredCustomerReducer } from './reducers/registered-customer.reducer';
import { loginCustomerReducer } from './reducers/login-customer.reducer';
import { loadingCustomerReducer } from './reducers/loading-customer.reducer';
import { customerErrorReducer } from './reducers/customer-error.reducer';
import { customerCheckoutReducer } from './reducers/customer-checkout.reducer';
import { updateCustomerConvertedReducer } from './reducers/update-customer-converted.reducer';
import { logoutCustomerReducer } from './reducers/logout-customer.reducer';
import { createCustomerReducer } from './reducers/create-customer.reducer';
import { createCustomerBillingAddressReducer } from './reducers/create-customer-billing-address.reducer';
import { createCustomerShippingAddressReducer } from './reducers/create-customer-shipping-address.reducer';
import { updateCustomerBillingAddressReducer } from './reducers/update-customer-billing-address.reducer';
import { updateCustomerShippingAddressReducer } from './reducers/update-customer-shipping-address.reducer';
import { updateCustomerSubscriptionReducer } from './reducers/update-customer-subscription.reducer';
import { updateCustomerReducer } from './reducers/update-customer.reducer';
import { initialCustomerState } from './state';
import { setStripeClientSecretReducer } from './reducers/set-stripe-client-secret.reducer';
import { setPaypalTokenReducer } from './reducers/set-paypal-token.reducer';
import { setPaypalStatusReducer } from './reducers/set-paypal-status.reducer';

export const customerSlice = createSlice({
  name: 'Customer',
  initialState: initialCustomerState,
  reducers: {
    authed: authedCustomerReducer,
    sse: authedSSEReducer,
    registered: registeredCustomerReducer,
    checkout: customerCheckoutReducer,
    clear: clearCustomerReducer,
    login: loginCustomerReducer,
    logout: logoutCustomerReducer,
    loading: loadingCustomerReducer,
    error: customerErrorReducer,
    create: createCustomerReducer,
    update: updateCustomerReducer,
    updateConverted: updateCustomerConvertedReducer,
    updateSubscription: updateCustomerSubscriptionReducer,
    createBillingAddress: createCustomerBillingAddressReducer,
    createShippingAddress: createCustomerShippingAddressReducer,
    updateBillingAddress: updateCustomerBillingAddressReducer,
    updateShippingAddress: updateCustomerShippingAddressReducer,
    setStripeClientSecret: setStripeClientSecretReducer,
    setPaypalToken: setPaypalTokenReducer,
    setPaypalStatus: setPaypalStatusReducer,
  },
});

export const customerSliceReducer = customerSlice.reducer;
