import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';
import { StyledTheme } from '../../interfaces';
import { responsive } from '../../utils';

export const StyledThemeBaseGlobals = createGlobalStyle<{ theme: StyledTheme }>`
  ${normalize}

  @font-face {
    font-family: 'Lola';
    font-display: auto;
    src: url('/fonts/fslola-medium-webfont.woff') format('woff'),
      url('/fonts/fslola-medium-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lola light';
    font-display: auto;
    src: url('/fonts/FS Lola Light Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro Bold';
    font-display: auto;
    src: url('/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro Light';
    font-display: auto;
    src: url('/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: lighter;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro SemiBold';
    font-display: auto;
    src: url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro Regular';
    font-display: auto;
    src: url('/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SourceSansPro LightItalic';
    font-display: auto;
    src: url('/fonts/SourceSansPro-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'LolaRegular';
    font-display: auto;
    src: url('/fonts/FSLola-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-size: 0.93rem;
    font-family: ${({ theme }) => theme.typography.mainFontFamily};
    scroll-padding-top: 6.5rem;
    scroll-behavior: smooth;

    ${responsive.desktop.standard(css`
      font-size: 1rem;
      line-height: 1.5rem;
    `)}
  }

  body.basket-open {
    overflow: hidden;
    scroll-behavior: none;
  }

  summary {
    list-style-type: none;
  }

  div {
    box-sizing: border-box;
  }

  p,
  label {
    font-family: ${({ theme }) => theme.typography.mainFontFamily};
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.palette.primary.color};
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.color};
    &:hover {
      color: ${({ theme }) => theme.palette.primaryButton.color};
    }
  }

  h1,h2,h3,h4 {
    color: ${({ theme }) => theme.palette.primary.color};
    font-family: ${({ theme }) => theme.typography.offsetFontFamily};
  }

  h1 {
    font-size: 1.875rem;
    line-height: 2rem;

    ${responsive.desktop.standard(css`
      font-size: 2.25rem;
      line-height: 2.5rem;
    `)}
  }

  h2 {
    font-size: 2rem;
    line-height: 2.25rem
  }

  h3 {
    font-size: 1.875rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 1.875rem;
    line-height: 2rem;
  }
`;
