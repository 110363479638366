import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface CustomerRegistrationFormMembers {
  email: FormMember;
  password: FormMember;
  confirm: FormMember;
}

export interface CustomerRegistrationFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: CustomerRegistrationFormMembers;
}

export const cleanCustomerRegistrationFormState: CustomerRegistrationFormState =
  {
    isValid: false,
    isTouched: false,
    isLoading: false,
    isSubmitted: false,
    members: {
      email: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      password: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      confirm: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
    },
  };

export const initialCustomerRegistrationFormState = appStorage.get(
  AppStorageKey.FORM_CUSTOMER_REGISTRATION_STATE,
  cleanCustomerRegistrationFormState
) as CustomerRegistrationFormState;

export const customerRegistrationFormSlice = createSlice({
  name: 'CustomerRegistrationForm',
  initialState: () => initialCustomerRegistrationFormState,
  reducers: {
    update: (
      state: CustomerRegistrationFormState,
      action: PayloadAction<CustomerRegistrationFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_CUSTOMER_REGISTRATION_STATE, state);
    },
  },
});
