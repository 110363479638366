import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';
import { CatalogDiscount } from '@hiwaldo/sdk/interfaces';

export interface CatalogDataState {
  discount?: CatalogDiscount;
  isPromoActive: boolean;
}

export const cleanCatalogDataState: CatalogDataState = {
  isPromoActive: false,
};

export const initialCatalogDataState = appStorage.get(
  AppStorageKey.DATA_CATALOG_STATE,
  cleanCatalogDataState
) as CatalogDataState;

export const catalogDataSlice = createSlice({
  name: 'CatalogData',
  initialState: initialCatalogDataState,
  reducers: {
    update: (
      state: CatalogDataState,
      action: PayloadAction<CatalogDataState>
    ) => {
      state.discount = action.payload.discount;
      state.isPromoActive = action.payload.isPromoActive;

      appStorage.set(AppStorageKey.DATA_CATALOG_STATE, state);

      return state;
    },
  },
});
