import {
  useIconEffect,
  IconProps,
  IconTemplateProps,
  IconArrowLeftOutlineTemplate,
  IconArrowRightTemplate,
  IconCheckTemplate,
  IconContactTemplate,
  IconCrossTemplate,
  IconHamburgerTemplate,
  IconLensesHydraBoostTemplate,
  IconLensesHydraBoostPlusTemplate,
  IconMailUnreadTemplate,
  IconNotificationTemplate,
  IconPlanMonthlyTemplate,
  IconPlanQuarterlyTemplate,
  IconPlanBiannualTemplate,
  IconReorderTemplate,
  IconSelectTickTemplate,
  IconSettingsTemplate,
  IconCalendarCheckTemplate,
  IconChevronCircleTemplate,
  IconChevronDownTemplate,
  IconChevronUpTemplate,
  IconCalendarEventTemplate,
  IconXTemplate,
  IconExclamationReactTemplate,
  IconExclamationTemplate,
  IconCheckEmptyTemplate,
  IconCheckCircleTemplate,
} from '../';

export const IconElement = (props: IconProps) => {
  const [state] = useIconEffect(props);

  const templateProps: IconTemplateProps = {
    ...state,
  };

  let template;
  switch (props.template) {
    case 'iconArrowLeftOutline':
      template = IconArrowLeftOutlineTemplate(templateProps);
      break;
    case 'iconArrowRight':
      template = IconArrowRightTemplate(templateProps);
      break;
    case 'iconCalendarCheck':
      template = IconCalendarCheckTemplate(templateProps);
      break;
    case 'iconCalendarEvent':
      template = IconCalendarEventTemplate(templateProps);
      break;
    case 'iconCheckCircle':
      template = IconCheckCircleTemplate(templateProps);
      break;
    case 'iconCheckEmpty':
      template = IconCheckEmptyTemplate(templateProps);
      break;
    case 'iconCheck':
      template = IconCheckTemplate(templateProps);
      break;
    case 'iconChevronCircle':
      template = IconChevronCircleTemplate(templateProps);
      break;
    case 'iconChevronDown':
      template = IconChevronDownTemplate(templateProps);
      break;
    case 'iconChevronUp':
      template = IconChevronUpTemplate(templateProps);
      break;
    case 'iconContact':
      template = IconContactTemplate(templateProps);
      break;
    case 'iconCross':
      template = IconCrossTemplate(templateProps);
      break;
    case 'iconExclamationReact':
      template = IconExclamationReactTemplate(templateProps);
      break;
    case 'iconExclamation':
      template = IconExclamationTemplate(templateProps);
      break;
    case 'iconHamburger':
      template = IconHamburgerTemplate(templateProps);
      break;
    case 'iconLensesHydraBoost':
      template = IconLensesHydraBoostTemplate(templateProps);
      break;
    case 'iconLensesHydraBoostPlus':
      template = IconLensesHydraBoostPlusTemplate(templateProps);
      break;
    case 'iconMailUnread':
      template = IconMailUnreadTemplate(templateProps);
      break;
    case 'iconNotification':
      template = IconNotificationTemplate(templateProps);
      break;
    case 'iconPlanBiannual':
      template = IconPlanBiannualTemplate(templateProps);
      break;
    case 'iconPlanMonthly':
      template = IconPlanMonthlyTemplate(templateProps);
      break;
    case 'iconPlanQuarterly':
      template = IconPlanQuarterlyTemplate(templateProps);
      break;
    case 'iconReorder':
      template = IconReorderTemplate(templateProps);
      break;
    case 'iconSelectTick':
      template = IconSelectTickTemplate(templateProps);
      break;
    case 'iconSettings':
      template = IconSettingsTemplate(templateProps);
      break;
    case 'iconX':
    default:
      template = IconXTemplate(templateProps);
      break;
  }

  return template;
};
