import { PayloadAction } from '@reduxjs/toolkit';
import { OrderListSearchState, OrderListState } from '../state';

export const loadingOrderListReducer = (
  state: OrderListState,
  action: PayloadAction<OrderListSearchState>
) => {
  state.loading = true;
  state.search = action.payload;
};
