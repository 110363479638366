import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { MdReorder } from 'react-icons/md';

export const IconReorderTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <MdReorder />
    </IconStyles>
  );
};
