import { combineReducers } from 'redux';
import { doctorListReducer } from './doctor-list/slice';
import { DoctorListState, initialDoctorListState } from './doctor-list/state';

export type DoctorsState = {
  doctorList: DoctorListState;
};

export const initialDoctorsState: DoctorsState = {
  doctorList: initialDoctorListState,
};

export const doctorsReducers = combineReducers({
  doctorList: doctorListReducer,
});
