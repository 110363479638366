import { GeolocationState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateGeolocationReducer = (
  state: GeolocationState,
  action: PayloadAction<GeolocationState>
) => {
  state = action.payload;

  appStorage.set(AppStorageKey.GEOLOCATION_STATE, state);
};
