import { createSlice } from '@reduxjs/toolkit';
import { loadingOrderReducer } from './reducers/loading-order.reducer';
import { updateOrderReducer } from './reducers/update-order.reducer';
import { orderErrorReducer } from './reducers/order-error.reducer';
import { savingOrderReducer } from './reducers/saving-order.reducer';
import { initialOrderState } from './state';
import { orderInvoiceReducer } from './reducers/order-invoice.reducer';

export const orderSlice = createSlice({
  name: 'Order',
  initialState: initialOrderState,
  reducers: {
    saving: savingOrderReducer,
    loading: loadingOrderReducer,
    update: updateOrderReducer,
    orderInvoice: orderInvoiceReducer,
    error: orderErrorReducer,
  },
});

export const orderReducer = orderSlice.reducer;
