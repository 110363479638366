import { CatalogTrial } from '@hiwaldo/sdk/interfaces';

export interface TrialState {
  data: CatalogTrial | null;
  loading?: boolean;
  error?: string;
}

export const initialTrialState: TrialState = {
  data: null,
  error: '',
  loading: false,
};
