import { CustomerState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { PayloadAction } from '@reduxjs/toolkit';

export const setPaypalTokenReducer = (
  state: CustomerState,
  action: PayloadAction<string>
) => {
  state.paypal.paypalToken = action.payload;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
