import { DiscountState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CatalogDiscount } from '@hiwaldo/sdk/interfaces';

export const updateDiscountReducer = (
  state: DiscountState,
  action: PayloadAction<CatalogDiscount>
) => {
  state.loading = false;
  state.data = action.payload;
};
