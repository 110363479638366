import { ToastHandlers, ToastState } from '../../..';
import { usePageNotificationEffect } from '../../../../state';

export function useToastEffect(): [ToastState, ToastHandlers] {
  const pageNotification = usePageNotificationEffect();

  const state: ToastState = {
    toasts: pageNotification.toasts,
  };

  const removeSlot = (id: string) => {
    pageNotification.remove(id);
  };

  const handlers: ToastHandlers = {
    onClose: removeSlot,
  };

  return [state, handlers];
}
