import { ChargeState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { Charge } from '@hiwaldo/sdk/interfaces';
export const updateChargeReducer = (
  state: ChargeState,
  action: PayloadAction<Charge>
) => {
  state.loading = false;
  state.data = action.payload;
};
