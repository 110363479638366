import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';
import {
  Cart,
  CatalogProductVariant,
  RenewalOrder,
} from '@hiwaldo/sdk/interfaces';

export interface BasketDataState {
  loading: boolean;
  basketOpen: boolean;
  basketConverted: boolean;
  customerAssigned: boolean;
  countItems: number;
  countProductVariants: number;
  countBundles: number;
  countTrials: number;
  includesLenses: boolean;
  includesTrial: boolean;
  includesBundle: boolean;
  includesBothEyes: boolean;
  includesDuplicateEyes: boolean;
  includesBLG: boolean;
  includesDrops: boolean;
  includesDoctor: boolean;
  includesPrescription: boolean;
  includesTax: boolean;

  cart?: Cart;
  secret?: string;
  token?: any;
  renewalForecast?: RenewalOrder;
  leftEye?: CatalogProductVariant;
  rightEye?: CatalogProductVariant;
}

export const cleanBasketDataState: BasketDataState = {
  loading: false,
  basketOpen: false,
  basketConverted: false,
  customerAssigned: false,
  countItems: 0,
  countProductVariants: 0,
  countBundles: 0,
  countTrials: 0,
  includesLenses: false,
  includesTrial: false,
  includesBundle: false,
  includesBothEyes: false,
  includesDuplicateEyes: false,
  includesBLG: false,
  includesDrops: false,
  includesDoctor: false,
  includesPrescription: false,
  includesTax: false,
};

export const initialBasketDataState = appStorage.get(
  AppStorageKey.DATA_BASKET_STATE,
  cleanBasketDataState
) as BasketDataState;

export const basketDataSlice = createSlice({
  name: 'BasketData',
  initialState: initialBasketDataState,
  reducers: {
    update: (
      state: BasketDataState,
      action: PayloadAction<BasketDataState>
    ) => {
      state.loading = action.payload.loading;
      state.basketOpen = action.payload.basketOpen;
      state.basketConverted = action.payload.basketConverted;
      state.customerAssigned = action.payload.customerAssigned;
      state.countItems = action.payload.countItems;
      state.countProductVariants = action.payload.countProductVariants;
      state.countBundles = action.payload.countBundles;
      state.countTrials = action.payload.countTrials;
      state.includesLenses = action.payload.includesLenses;
      state.includesTrial = action.payload.includesTrial;
      state.includesBundle = action.payload.includesBundle;
      state.includesBothEyes = action.payload.includesBothEyes;
      state.includesDuplicateEyes = action.payload.includesDuplicateEyes;
      state.includesBLG = action.payload.includesBLG;
      state.includesDrops = action.payload.includesDrops;
      state.includesDoctor = action.payload.includesDoctor;
      state.includesPrescription = action.payload.includesPrescription;
      state.includesTax = action.payload.includesTax;
      state.cart = action.payload.cart;
      state.secret = action.payload.secret;
      state.token = action.payload.token;
      state.renewalForecast = action.payload.renewalForecast;
      state.leftEye = action.payload.leftEye;
      state.rightEye = action.payload.rightEye;

      appStorage.set(AppStorageKey.DATA_BASKET_STATE, state);

      return state;
    },
  },
});
