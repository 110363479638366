import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { CiCircleCheck } from 'react-icons/ci';

export const IconCheckEmptyTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <CiCircleCheck />
    </IconStyles>
  );
};
