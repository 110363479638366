import { ForecastState, RenewalOrderCalculation } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const updateForecastReducer = (
  state: ForecastState,
  action: PayloadAction<RenewalOrderCalculation>
) => {
  state.loading = false;
  state.data = action.payload;
};
