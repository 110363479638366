import { createSlice } from '@reduxjs/toolkit';
import { initialClinicListState } from './state';
import { clinicListErrorReducer } from './reducers/clinic-list-error.reducer';
import { loadingClinicListReducer } from './reducers/loading-clinic-list.reducer';
import { searchClinicListReducer } from './reducers/search-clinic-list.reducer';
import { updateClinicListReducer } from './reducers/update-clinic-list.reducer';

export const clinicListSlice = createSlice({
  name: 'ClinicList',
  initialState: initialClinicListState,
  reducers: {
    loading: loadingClinicListReducer,
    search: searchClinicListReducer,
    update: updateClinicListReducer,
    error: clinicListErrorReducer,
  },
});

export const clinicListReducer = clinicListSlice.reducer;
