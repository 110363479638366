import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';

export interface NewsletterPageState {
  isSubmitted: boolean;
  isSubscriber: boolean;
  loading: boolean;
  success: boolean;
  email?: string;
  errors?: string[];
}

export const cleanNewsletterPageState: NewsletterPageState = {
  isSubmitted: false,
  isSubscriber: false,
  loading: false,
  success: false,
};

export const initialNewsletterPageState = appStorage.get(
  AppStorageKey.PAGE_NEWSLETTER_STATE,
  cleanNewsletterPageState
) as NewsletterPageState;

export const newsletterPageSlice = createSlice({
  name: 'NewsletterPage',
  initialState: initialNewsletterPageState,
  reducers: {
    update: (
      state: NewsletterPageState,
      action: PayloadAction<NewsletterPageState>
    ) => {
      state.isSubmitted = action.payload.isSubmitted;
      state.isSubscriber = action.payload.isSubscriber;
      state.loading = action.payload.loading;
      state.success = action.payload.success;
      state.email = action.payload.email;
      state.errors = action.payload.errors;

      appStorage.set(AppStorageKey.PAGE_NEWSLETTER_STATE, state);

      return state;
    },
  },
});
