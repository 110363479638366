import { combineReducers } from 'redux';
import * as cart from './cart';
import * as charge from './charge';
import {
  initialOrderListState,
  initialOrderState,
  orderListReducer,
  OrderListState,
  orderReducer,
  OrderState,
} from './order';
import {
  initialCheckoutState,
  checkoutReducer,
  CheckoutState,
} from './checkout';
import { initialFunnelState, funnelReducer, FunnelState } from './funnel';

export interface OrdersState {
  cart: cart.CartState;
  charge: charge.ChargeState;
  order: OrderState;
  orderList: OrderListState;
  checkout: CheckoutState;
  funnel: FunnelState;
}

export const initialOrdersState: OrdersState = {
  cart: cart.initialCartState,
  charge: charge.initialChargeState,
  order: initialOrderState,
  orderList: initialOrderListState,
  checkout: initialCheckoutState,
  funnel: initialFunnelState,
};

export const ordersReducers = combineReducers({
  cart: cart.cartReducer,
  charge: charge.chargeReducer,
  order: orderReducer,
  orderList: orderListReducer,
  checkout: checkoutReducer,
  funnel: funnelReducer,
});

export * from './cart';
export * from './checkout';
export * from './funnel';
