import { ThemePalette } from '../..';

export const ThemeBasePalette = (): ThemePalette => {
  return {
    mainBackgroundColor: '#0032A0',
    mainForegroundColor: '#F8F8F8',
    mainBorderColor: '#FFFFFF',
    mainFontColor: '#FFFFFF',
    mainFontHighlightColor: '#FE5000',
    mainFontDulledColor: '#F8F8F8',
    mainLinkColor: '#FE5000',
    mainLinkHoverColor: '#FFFFFF',

    lightBackgroundColor: '#e5eaf5',

    headerBackgroundColor: '#FFFFFF',
    headerForegroundColor: '#0032A0',
    headerBorderColor: '#0032A0',
    headerFontColor: '#0032A0',
    headerFontHighlightColor: '#FE5000',
    headerFontDulledColor: '#F8F8F8',
    headerLinkColor: '#0032A0',
    headerLinkHoverColor: '#FFFFFF',

    offsetBackgroundColor: 'rgba(217, 149, 180, 0.23)',
    offsetForegroundColor: '#6E664F',
    offsetBorderColor: '#7C7561',
    offsetFontColor: '#9696A5',
    offsetFontHighlightColor: '#CCCCE0',
    offsetFontDulledColor: '#66667A',
    offsetLinkColor: '#9696A5',
    offsetLinkHoverColor: '#CCCCE0',

    contentBackgroundColor: '#F8F8F8',
    contentForegroundColor: '#F8F8F8',
    contentBorderColor: '#FFFFFF',
    contentFontColor: '#0032A0',
    contentFontHighlightColor: '#FE5000',
    contentFontDulledColor: '#F8F8F8',
    contentLinkColor: '#FE5000',
    contentLinkHoverColor: '#FFFFFF',

    ancillaryBackgroundColor: '#D3DCEF',
    ancillaryForegroundColor: '#F2F5Fa',
    ancillaryBorderColor: '#FFFFFF',
    ancillaryFontColor: '#0032A0',
    ancillaryFontHighlightColor: '#FE5000',
    ancillaryFontDulledColor: '#F8F8F8',
    ancillaryLinkColor: '#FE5000',
    ancillaryLinkTextColor: '#FFFFFF',
    ancillaryLinkHoverColor: '#FFFFFF',

    primaryBackgroundColor: '#0032A0',
    primaryBorderColor: '#FE5000',
    primaryTextColor: '#FFFFFF',
    primaryShadowColor: '#F8F8F8',
    primaryHoverBackgroundColor: '#FFFFFF',
    primaryHoverBorderColor: '#0032A0',
    primaryHoverTextColor: '#0032A0',
    primaryHoverShadowColor: '#F8F8F8',
    primaryFocusBackgroundColor: '#FFFFFF',
    primaryFocusBorderColor: '#0032A0',
    primaryFocusTextColor: '#FFFFFF',
    primaryFocusShadowColor: '#F8F8F8',

    textInputBackgroundColor: '#FFFFFF',
    textInputForegroundColor: '#0032A0',
    textInputBorderColor: '#0032A0',
    textInputFontColor: '#0032A0',
    textInputFontHighlightColor: '#FE5000',
    textInputFontDulledColor: '#F8F8F8',
    textInputLinkColor: '#0032A0',
    textInputLinkHoverColor: '#FFFFFF',

    warningBackgroundColor: '#F4E4D0',
    warningBorderColor: '#E28822',
    warningTextColor: '#E28822',
    warningShadowColor: '#20202D',
    warningHoverBackgroundColor: '#FFE8CE',
    warningHoverBorderColor: '#F49324',
    warningHoverTextColor: '#F49324',
    warningHoverShadowColor: '#20202D',
    warningFocusBackgroundColor: '#FFE8CE',
    warningFocusBorderColor: '#F49324',
    warningFocusTextColor: '#F49324',
    warningFocusShadowColor: '#20202D',

    validBackgroundColor: '#DBEDCE',
    validBorderColor: '#64AF23',
    validTextColor: '#64AF23',
    validShadowColor: '#20202D',
    validHoverBackgroundColor: '#EAFFDB',
    validHoverBorderColor: '#6DBF26',
    validHoverTextColor: '#6DBF26',
    validHoverShadowColor: '#20202D',
    validFocusBackgroundColor: '#EAFFDB',
    validFocusBorderColor: '#6DBF26',
    validFocusTextColor: '#6DBF26',
    validFocusShadowColor: '#20202D',

    invalidBackground: '#F9E3E8',
    invalidBorderColor: '#BC2546',
    invalidTextColor: '#BC2546',
    invalidShadowColor: '#20202D',
    invalidHoverBackgroundColor: '#FFDBE1',
    invalidHoverBorderColor: '#CC284C',
    invalidHoverTextColor: '#CC284C',
    invalidHoverShadowColor: '#20202D',
    invalidFocusBackgroundColor: '#FFDBE1',
    invalidFocusBorderColor: '#CC284C',
    invalidFocusTextColor: '#CC284C',
    invalidFocusShadowColor: '#20202D',

    waldoSands: '#eae6dd',
    waldoVision: '#ecbcac',
  };
};
