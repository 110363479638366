import { ThemeTypography } from '../..';

export const ThemeBaseTypography = (): ThemeTypography => {
  return {
    mainFontFamily: 'SourceSansPro, Helvetica, Arial, sans-serif',
    mainFontWeight: 'normal',
    mainFontStyle: 'normal',
    mainFontVariant: 'normal',

    mainFontFamilyBold: 'SourceSansPro Bold',
    mainFontFamilyLight: 'SourceSansPro Light',
    mainFontFamilyMedium: 'SourceSansPro SemiBold',

    offsetFontFamily: 'Lola, Helvetica, Arial',
    offsetFontWeight: 'normal',
    offsetFontStyle: 'normal',
    offsetFontVariant: 'normal',

    contentFontFamily: 'LolaRegular, Lola, Helvetica',
    contentFontWeight: 'normal',
    contentFontStyle: 'normal',
    contentFontVariant: 'normal',

    ancillaryFontFamily: 'Helvetica',
    ancillaryFontWeight: 'normal',
    ancillaryFontStyle: 'normal',
    ancillaryFontVariant: 'normal',
  };
};
