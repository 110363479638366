import { Customer } from '@hiwaldo/sdk/interfaces';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { PrescriptionStatus } from '@hiwaldo/sdk';

export interface CustomerState {
  customer?: Customer;
  authedId: number | null;
  authedSSE: boolean;
  registered: boolean;
  converted: boolean;
  isSubscriber: boolean;
  isVerified: boolean;
  hasPrescription: boolean;
  hasTrialed: boolean;
  statusDoctorAuth: PrescriptionStatus | null;
  hasRenewalDate: boolean;
  includesBothEyes: boolean;
  loggedIn: boolean;
  loading: boolean;
  error?: string;
  clientSecret: string | null;
  paypal: {
    paypalToken: string | null;
    paypalStatus: string | null;
  };
}

export const initialCustomerState: CustomerState = appStorage.get(
  AppStorageKey.CUSTOMER_STATE,
  {
    authedId: null,
    authedSSE: false,
    registered: false,
    converted: false,
    isSubscriber: false,
    hasTrialed: false,
    isVerified: false,
    hasPrescription: false,
    statusDoctorAuth: null,
    hasRenewalDate: false,
    includesBothEyes: true,
    loggedIn: false,
    error: '',
    loading: false,
    clientSecret: '',
    paypal: {
      paypalToken: '',
      paypalStatus: '',
    },
  }
) as CustomerState;
