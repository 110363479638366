import { combineReducers } from 'redux';

import {
  surveyWelcomeFormFlowSlice,
  SurveyWelcomeFormFlowState,
} from './survey-welcome-flow.redux';

export interface SurveysFormState {
  welcome: SurveyWelcomeFormFlowState;
}

export const initialSurveysFormState: SurveysFormState = {
  welcome: surveyWelcomeFormFlowSlice.getInitialState(),
};

export const surveysFormReducers = combineReducers({
  welcome: surveyWelcomeFormFlowSlice.reducer,
});

export * from './survey-welcome-flow.redux';
