import { NewsletterSignUpState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateNewsletterSignUpReducer = (
  state: NewsletterSignUpState,
  action: PayloadAction<boolean>
) => {
  state.hasSignedUp = action.payload;

  appStorage.set(AppStorageKey.NEWSLETTER_STATE, state);
};
