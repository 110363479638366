import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface AccountPrescriptionFormMembers {
  leftEyePower: FormMember;
  rightEyePower: FormMember;
}

export interface AccountPrescriptionFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: AccountPrescriptionFormMembers;
}

export const cleanAccountPrescriptionFormState: AccountPrescriptionFormState = {
  isValid: true,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    leftEyePower: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    rightEyePower: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialAccountPrescriptionFormState = appStorage.get(
  AppStorageKey.FORM_ACCOUNT_PRESCRIPTION_STATE,
  cleanAccountPrescriptionFormState
) as AccountPrescriptionFormState;

export const accountPrescriptionFormSlice = createSlice({
  name: 'AccountPrescriptionForm',
  initialState: () => initialAccountPrescriptionFormState,
  reducers: {
    update: (
      state: AccountPrescriptionFormState,
      action: PayloadAction<AccountPrescriptionFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_ACCOUNT_PRESCRIPTION_STATE, state);
    },
  },
});
