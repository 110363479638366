import { CustomerState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { PayloadAction } from '@reduxjs/toolkit';

export const setStripeClientSecretReducer = (
  state: CustomerState,
  action: PayloadAction<string>
) => {
  state.clientSecret = action.payload;
  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
