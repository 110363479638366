import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

export const responsive = {
  mobile: {
    small: (
      args: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>
    ) => css`
      @media (max-width: 375px) {
        ${args}
      }
    `,
    standard: (
      args: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>
    ) => css`
      @media (max-width: 425px) {
        ${args}
      }
    `,
    large: (
      args: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>
    ) => css`
      @media (max-width: 767px) {
        ${args}
      }
    `,
  },
  desktop: {
    small: (
      args: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>
    ) => css`
      @media (min-width: 768px) {
        ${args}
      }
    `,
    standard: (
      args: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>
    ) => css`
      @media (min-width: 1024px) {
        ${args}
      }
    `,
    large: (
      args: FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>
    ) => css`
      @media (min-width: 1440px) {
        ${args}
      }
    `,
  },
};
