import { PlanListSearchState, PlanListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const loadingPlanListReducer = (
  state: PlanListState,
  action: PayloadAction<PlanListSearchState>
) => {
  state.loading = true;
  state.loaded = false;
  state.search = action.payload;
};
