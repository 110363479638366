import { appStorage, AppStorageKey } from '../../../../helpers';
import { Region } from '@hiwaldo/sdk';

export interface GeolocationState {
  geoRegion: Region | null;
  country: string | null;
  loading: boolean;
  redirected: boolean;
  error?: string;
}

export const initialGeolocationState: GeolocationState = appStorage.get(
  AppStorageKey.GEOLOCATION_STATE,
  {
    geoRegion: null,
    country: null,
    loading: false,
    redirected: false,
  }
) as GeolocationState;
