import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';
import { RenewalSubscriptionStatus } from '@hiwaldo/sdk';

export type AccountPage = 'account' | 'payment-details';

export type AccountPaymentOption =
  | 'stripe'
  | 'paypal'
  | 'google-pay'
  | 'apple-pay';

export interface AccountPrescriptionEye {
  eye: 'left' | 'right';
  required: boolean;
  id?: number;
  power?: string;
  productVariantId?: number;
}

export interface AccountPrescriptionData {
  id: number;
  leftEye: AccountPrescriptionEye;
  rightEye: AccountPrescriptionEye;
  approved: boolean;
}

export interface AccountSubscriptionProduct {
  id: number;
  title: string;
  quantity: number;
}

export interface AccountSubscriptionData {
  id: number;
  planId: number;
  planTitle: string;
  productId: number;
  productTitle: string;
  familyId: number;
  familyTitle: string;
  frequencyId?: number;
  frequencyDays?: number;
  status?: RenewalSubscriptionStatus;
  nextRenewalDate?: string;
  lastChargeDate?: string;
  nextChargeDate?: string;
  nextChargeAmount?: number;
  preferredDay?: number;
  prefferedDate?: number;
  resumeDate?: string;
  addedProducts?: AccountSubscriptionProduct[];
}

export interface AccountDataState {
  page: AccountPage;
  completedAuthentication: boolean;
  subscription?: AccountSubscriptionData;
  subscriptionProcessing?: boolean;
  subscriptionUpdated?: boolean;
  subscriptionCancelled?: boolean;
  subscriptionPaused?: boolean;
  subscriptionError?: string | boolean;
  prescription?: AccountPrescriptionData;
  prescriptionProcessing?: boolean;
  prescriptionUpdated?: boolean;
  prescriptionError?: string | boolean;
  updatedPayment: AccountPaymentOption | undefined;
  currentPayment: AccountPaymentOption | undefined;

  stripeProcessing: boolean;
  stripeSecret?: string;
  stripeError?: string | boolean;

  paypalProcessing: boolean;
  paypalToken?: any;
  paypalError?: string | boolean;
  googlePayError?: string | boolean;
  applePayError?: string | boolean;
}

export const cleanAccountDataState: AccountDataState = {
  page: 'account',
  completedAuthentication: false,
  updatedPayment: undefined,
  currentPayment: undefined,
  stripeProcessing: false,
  paypalProcessing: false,
};

export const initialAccountDataState = appStorage.get(
  AppStorageKey.DATA_ACCOUNT_STATE,
  cleanAccountDataState
) as AccountDataState;

export const accountDataSlice = createSlice({
  name: 'AccountData',
  initialState: initialAccountDataState,
  reducers: {
    update: (
      state: AccountDataState,
      action: PayloadAction<AccountDataState>
    ) => {
      state.page = action.payload.page;
      state.completedAuthentication = action.payload.completedAuthentication;
      state.subscription = action.payload.subscription;
      state.subscriptionProcessing = action.payload.subscriptionProcessing;
      state.subscriptionUpdated = action.payload.subscriptionUpdated;
      state.subscriptionCancelled = action.payload.subscriptionCancelled;
      state.subscriptionPaused = action.payload.subscriptionPaused;
      state.subscriptionError = action.payload.subscriptionError;
      state.prescription = action.payload.prescription;
      state.prescriptionProcessing = action.payload.prescriptionProcessing;
      state.prescriptionUpdated = action.payload.prescriptionUpdated;
      state.prescriptionError = action.payload.prescriptionError;
      state.updatedPayment = action.payload.updatedPayment;
      state.currentPayment = action.payload.currentPayment;
      state.stripeProcessing = action.payload.stripeProcessing;
      state.stripeSecret = action.payload.stripeSecret;
      state.stripeError = action.payload.stripeError;
      state.paypalProcessing = action.payload.paypalProcessing;
      state.paypalToken = action.payload.paypalToken;
      state.paypalError = action.payload.paypalError;
      state.googlePayError = action.payload.googlePayError;
      state.applePayError = action.payload.applePayError;

      appStorage.set(AppStorageKey.DATA_ACCOUNT_STATE, state);

      return state;
    },
  },
});
