import { CartState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethods } from '../../../../../data/subscription/payment-methods';

export const setPaymentGatewayReducer = (
  state: CartState,
  action: PayloadAction<PaymentMethods | null>
) => {
  state.paymentGateway = action.payload;

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
