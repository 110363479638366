import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { FunnelProductTypeTrial } from '../../../../../components';
import { AppStorageKey, appStorage } from '../../../../../helpers';

export const setProductTrialTypeReducer = (
  state: FunnelState,
  action: PayloadAction<FunnelProductTypeTrial>
) => {
  state.productTrialType = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
