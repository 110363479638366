import { UserFormAnswer } from '@hiwaldo/sdk/interfaces';

export interface UserFormAnswerState {
  data: UserFormAnswer | null;
  loading?: boolean;
  error?: string;
}

export const initialUserFormAnswerState: UserFormAnswerState = {
  data: null,
  error: '',
  loading: false,
};
