import jwt from 'jsonwebtoken';

export enum AppStorageKey {
  FUNNEL_CACHE = 'funnelCache',
  MEMBER_TOKEN = 'MemberToken',
  MEMBER_STATE = 'MemberState',
  PATIENT_STATE = 'PatientState',
  CART_CLIENT_ID = 'CartClientId',
  DOCTOR_STATE = 'DoctorState',
  GTM_STATE = 'GTMState',
  NEWSLETTER_SIGN_UP_STATE = 'NewsletterSignUpState',
  COOKIE_POLICY = 'Cookie policy',
  HBP_PROMO = 'HBPPromo',

  ACCOUNT_STATE = 'WaldoAccountState',
  AUTH_TOKEN = 'WaldoAuthToken',
  SSE_TOKEN = 'WaldoSSEToken',
  SSE_PING = 'WaldoSSEPing',
  CART_STATE = 'WaldoCartState',
  BASKET_STATE = 'WaldoBasketState',
  CATALOG_STATE = 'WaldoCatalogState',
  CUSTOMER_STATE = 'WaldoCustomerState',
  CHECKOUT_STATE = 'WaldoCheckoutState',
  FUNNEL_STATE = 'WaldoFunnelState',
  GEOLOCATION_STATE = 'WaldoGeolocationState',
  NEWSLETTER_STATE = 'WaldoNewsletterState',
  POLICY_STATE = 'WaldoPolicyState',
  RENEWAL_STATE = 'WaldoRenewalState',
  WELCOME_STATE = 'WaldoWelcomeState',
  WINDOW_STATE = 'WaldoWindowState',
  GEOLOCATION_RESPONSE = 'WaldoGeolocationResponse',
  RENEWAL_JOB = 'WaldoRenewalJob',
  LOGIN_REFERRER = 'WaldoLoginReferrer',
  PROMO_STATE = 'WaldoPromoState',

  DATA_ACCOUNT_STATE = 'WaldoDataAccountState',
  DATA_BASKET_STATE = 'WaldoDataBasketState',
  DATA_CATALOG_STATE = 'WaldoDataCatalogState',
  DATA_CHECKOUT_STATE = 'WaldoDataCheckoutState',
  DATA_CUSTOMER_STATE = 'WaldoDataCustomerState',
  DATA_ORDER_STATE = 'WaldoDataOrderState',

  FORM_ACCOUNT_STRIPE_STATE = 'WaldoFormAccountStripeState',
  FORM_ACCOUNT_SUBSCRIPTION_STATE = 'WaldoFormAccountSubscriptionState',
  FORM_ACCOUNT_RESUME_STATE = 'WaldoFormAccountResumeState',
  FORM_ACCOUNT_REACTIVATION_STATE = 'WaldoFormAccountReactivationState',
  FORM_ACCOUNT_PAUSE_STATE = 'WaldoFormAccountPauseState',
  FORM_ACCOUNT_CANCEL_STATE = 'WaldoFormAccountCancelState',
  FORM_ACCOUNT_PRESCRIPTION_STATE = 'WaldoFormAccountPrescriptionState',
  FORM_BASKET_DISCOUNT_STATE = 'WaldoFormBasketDiscountState',
  FORM_CAPTURE_NEWSLETTER_STATE = 'WaldoFormCaptureNewsletterState',
  FORM_CHECKOUT_FLOW_STATE = 'WaldoFormCheckoutFlowState',
  FORM_CHECKOUT_STRIPE_STATE = 'WaldoFormCheckoutStripeState',
  FORM_CUSTOMER_REGISTRATION_STATE = 'WaldoFormCustomerRegistrationState',
  FORM_CUSTOMER_SHIPPING_BILLING_STATE = 'WaldoFormCustomerShippingBillingState',
  FORM_SURVEY_WELCOME_FLOW_STATE = 'WaldoFormSurveyWelcomeFlowState',

  PAGE_GEOLOCATION_STATE = 'WaldoPageGeolocationState',
  PAGE_NEWSLETTER_STATE = 'WaldoPageNewsletterState',
  PAGE_MENU_STATE = 'WaldoPageMenuState',
  PAGE_NOTIFICATION_STATE = 'WaldoPageNotificationState',
  PAGE_POLICY_STATE = 'WaldoPagePolicyState',
  PAGE_WELCOME_STATE = 'WaldoPageWelcomeState',

  STORE_CATALOG = 'WaldoStoreCatalog',
  PAID_CART = 'WaldoPaidCart',
  COMPLETED_FUNNEL = 'WaldoCompletedFunnel',
}

export const appStorage = {
  protected: [
    AppStorageKey.CART_STATE,
    AppStorageKey.FUNNEL_STATE,
    AppStorageKey.SSE_TOKEN,
    AppStorageKey.DATA_BASKET_STATE,
    AppStorageKey.DATA_CHECKOUT_STATE,
    AppStorageKey.DATA_CUSTOMER_STATE,
    AppStorageKey.DATA_ORDER_STATE,
  ] as AppStorageKey[],
  get: <T>(
    key: AppStorageKey,
    fallback?: T,
    validate = true
  ): T | undefined => {
    let parsed;
    let secure = true;

    if (appStorage.protected.indexOf(key) >= 0 && validate) {
      if ((typeof localStorage as any) === 'undefined') {
        parsed = fallback;
        secure = false;
      } else {
        let token = localStorage.getItem(AppStorageKey.AUTH_TOKEN);

        if (token) {
          token = JSON.parse(token) as string;

          const date = new Date();
          const jwtDecoded = jwt.decode(token, {
            complete: true,
          });

          if (!jwtDecoded || typeof jwtDecoded.payload === 'string') {
            parsed = fallback;
            secure = false;
          } else if (
            jwtDecoded.payload.exp &&
            jwtDecoded.payload.exp * 1000 < date.getTime() - 864000000
          ) {
            parsed = fallback;
            secure = false;

            appStorage.clean();
          }
        }
      }
    }

    if (secure || !validate) {
      try {
        const item = localStorage.getItem(key);
        parsed = JSON.parse(item as string) as T;
      } catch (e) {
        parsed = undefined;
      }
    }

    return parsed ?? fallback;
  },
  set: (key: AppStorageKey, data: any): void => {
    localStorage.setItem(key, JSON.stringify(data));
  },
  remove: (key: AppStorageKey): void => {
    localStorage.removeItem(key);
  },
  clean: (): void => {
    localStorage.removeItem(AppStorageKey.CUSTOMER_STATE);
    localStorage.removeItem(AppStorageKey.CART_STATE);
    localStorage.removeItem(AppStorageKey.FUNNEL_STATE);
    localStorage.removeItem(AppStorageKey.AUTH_TOKEN);
    localStorage.removeItem(AppStorageKey.SSE_TOKEN);

    localStorage.removeItem(AppStorageKey.DATA_ACCOUNT_STATE);
    localStorage.removeItem(AppStorageKey.DATA_BASKET_STATE);
    localStorage.removeItem(AppStorageKey.DATA_CATALOG_STATE);
    localStorage.removeItem(AppStorageKey.DATA_CHECKOUT_STATE);
    localStorage.removeItem(AppStorageKey.DATA_CUSTOMER_STATE);
    localStorage.removeItem(AppStorageKey.DATA_ORDER_STATE);

    localStorage.removeItem(AppStorageKey.FORM_ACCOUNT_STRIPE_STATE);
    localStorage.removeItem(AppStorageKey.FORM_ACCOUNT_SUBSCRIPTION_STATE);
    localStorage.removeItem(AppStorageKey.FORM_ACCOUNT_PAUSE_STATE);
    localStorage.removeItem(AppStorageKey.FORM_ACCOUNT_CANCEL_STATE);
    localStorage.removeItem(AppStorageKey.FORM_ACCOUNT_PRESCRIPTION_STATE);
    localStorage.removeItem(AppStorageKey.FORM_BASKET_DISCOUNT_STATE);
    localStorage.removeItem(AppStorageKey.FORM_CAPTURE_NEWSLETTER_STATE);
    localStorage.removeItem(AppStorageKey.FORM_CHECKOUT_FLOW_STATE);
    localStorage.removeItem(AppStorageKey.FORM_CHECKOUT_STRIPE_STATE);
    localStorage.removeItem(AppStorageKey.FORM_CUSTOMER_REGISTRATION_STATE);
    localStorage.removeItem(AppStorageKey.FORM_CUSTOMER_SHIPPING_BILLING_STATE);
    localStorage.removeItem(AppStorageKey.FORM_SURVEY_WELCOME_FLOW_STATE);

    localStorage.removeItem(AppStorageKey.PAGE_NEWSLETTER_STATE);
    localStorage.removeItem(AppStorageKey.PAGE_MENU_STATE);
    localStorage.removeItem(AppStorageKey.PAGE_NOTIFICATION_STATE);
    localStorage.removeItem(AppStorageKey.PAGE_POLICY_STATE);
    localStorage.removeItem(AppStorageKey.PAGE_WELCOME_STATE);

    localStorage.removeItem(AppStorageKey.PAID_CART);
    localStorage.removeItem(AppStorageKey.COMPLETED_FUNNEL);
  },
};
