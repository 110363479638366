import { combineReducers } from 'redux';
import { initialJobState, jobReducer, JobState } from './job';
import {
  initialForecastState,
  forecastReducer,
  ForecastState,
} from './forecast';

export interface RenewalState {
  forecast: ForecastState;
  job: JobState;
}

export const initialRenewalState: RenewalState = {
  forecast: initialForecastState,
  job: initialJobState,
};

export const renewalReducers = combineReducers({
  forecast: forecastReducer,
  job: jobReducer,
});
