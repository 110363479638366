import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CustomerSubscription } from '@hiwaldo/sdk/interfaces/renewal';

export const updateCustomerSubscriptionReducer = (
  state: CustomerState,
  action: PayloadAction<CustomerSubscription>
) => {
  state.customer!.activeCustomerSubscription = action.payload;

  if (action.payload && !action.payload.nextRenewal) {
    state.hasRenewalDate = false;

    const preliminaryRenewalDate = new Date();
    preliminaryRenewalDate.setTime(
      preliminaryRenewalDate.getTime() + 86400000 * 12
    );

    state.customer!.activeCustomerSubscription!.nextRenewal =
      preliminaryRenewalDate.toISOString();
  } else if (action.payload.nextRenewal) {
    state.hasRenewalDate = true;
  }
};
