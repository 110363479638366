import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppStorageKey, appStorage } from '../../../../../helpers';

export const setRightEyePowerReducer = (
  state: FunnelState,
  action: PayloadAction<string>
) => {
  state.rightEyePower = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
