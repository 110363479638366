import { createSlice } from '@reduxjs/toolkit';
import { loadingClinicReducer } from './reducers/loading-clinic.reducer';
import { updateClinicReducer } from './reducers/update-clinic.reducer';
import { clinicErrorReducer } from './reducers/clinic-error.reducer';
import { initialClinicState } from './state';

export const clinicSlice = createSlice({
  name: 'Clinic',
  initialState: initialClinicState,
  reducers: {
    loading: loadingClinicReducer,
    update: updateClinicReducer,
    error: clinicErrorReducer,
  },
});

export const clinicReducer = clinicSlice.reducer;
