import { Charge } from '@hiwaldo/sdk/interfaces';

export interface ChargeState {
  data: Charge | null;
  loading?: boolean;
  error?: string;
}

export const initialChargeState: ChargeState = {
  data: null,
  error: '',
  loading: false,
};
