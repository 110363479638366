import { CartState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { Cart } from '@hiwaldo/sdk/interfaces';

export const createCartReducer = (
  state: CartState,
  action: PayloadAction<Cart>
) => {
  state.cart = action.payload;

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
