import { CartState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { CartPrescription } from '@hiwaldo/sdk/interfaces';

export const updateCartPrescriptionReducer = (
  state: CartState,
  action: PayloadAction<CartPrescription>
) => {
  state.cart!.prescription = action.payload;

  state.countItems = state.countProductVariants + state.countBundles;

  state.includesLenses = true;
  state.includesDoctor = !!state.cart?.doctor;
  state.includesPrescription = !!(
    state.cart?.prescription &&
    (state.cart!.prescription?.leftEyeProductVariant ||
      state.cart!.prescription?.rightEyeProductVariant)
  );

  if (
    state.includesDoctor &&
    state.includesPrescription &&
    state.includesTrial
  ) {
    state.countItems++;
  }

  state.includesBothEyes = !!(
    state.includesPrescription &&
    state.cart!.prescription?.leftEyeProductVariant &&
    state.cart!.prescription?.rightEyeProductVariant
  );

  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
