import { EmailNotificationState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const errorEmailNotificationReducer = (
  state: EmailNotificationState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.loading = false;
};
