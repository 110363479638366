import { FaqListSearchState, FaqListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const loadingFaqListReducer = (
  state: FaqListState,
  action?: PayloadAction<FaqListSearchState>
) => {
  state.loading = true;

  if (action) {
    state.search = action.payload;
  }
};
