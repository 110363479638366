import { CatalogPlanList } from '@hiwaldo/sdk/interfaces';

export interface PlanListState {
  search: PlanListSearchState;
  list: CatalogPlanList;
  initialized: boolean;
  loaded: boolean;
  loading?: boolean;
  error?: string;
}

export interface PlanListSearchState {
  page: number;
  filter: PlanListFilter;
}

export interface PlanListFilter {
  skip: number;
  take: number;
  orderBy: { [key: string]: 'asc' | 'desc' };
  where: {
    id?: string;
    title?: string;
    search?: string;
  };
}

export const initialPlanListSearchState: PlanListSearchState = {
  page: 0,
  filter: {
    skip: 0,
    take: 12,
    orderBy: { id: 'desc' },
    where: {},
  },
};

export const initialPlanListState: PlanListState = {
  search: initialPlanListSearchState,
  list: { rows: 0, data: [] },
  error: '',
  initialized: false,
  loaded: false,
  loading: false,
};
