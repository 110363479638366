import React from 'react';
import { IconStyles, IconTemplateProps } from '../';

export const IconChevronCircleTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <img className="rotate180" src="/images/icons/close-arrow.svg" />
    </IconStyles>
  );
};
