import { CustomerState } from '../state';
import { appStorage } from '../../../../../helpers';

export const logoutCustomerReducer = (state: CustomerState) => {
  appStorage.clean();

  state.authedId = null;
  state.authedSSE = false;
  state.registered = false;
  state.customer = undefined;
  state.authedId = null;
  state.loggedIn = false;
  state.isSubscriber = false;
  state.isVerified = false;
  state.hasPrescription = false;
  state.hasTrialed = false;
  state.statusDoctorAuth = null;
  state.converted = false;
};
