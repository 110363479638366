import { ClinicList, ClinicListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const updateClinicListReducer = (
  state: ClinicListState,
  action: PayloadAction<ClinicList>
) => {
  state.loading = false;
  state.list = action.payload;
};
