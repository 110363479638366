export interface EmailNotificationState {
  email: string | null;
  loading: boolean;
  success: boolean;
  error?: string;
}

export const initialEmailNotificationState: EmailNotificationState = {
  loading: false,
  email: null,
  success: false,
  error: '',
};
