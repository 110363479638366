import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface CustomerShippingBillingFormMembers {
  shippingFirstName: FormMember;
  shippingLastName: FormMember;
  shippingCompany: FormMember;
  shippingLine1: FormMember;
  shippingLine2: FormMember;
  shippingCity: FormMember;
  shippingState: FormMember;
  shippingPostalCode: FormMember;
  shippingCountry: FormMember;
  shippingPhone: FormMember;
  matchAddress: FormMember;
  billingFirstName: FormMember;
  billingLastName: FormMember;
  billingCompany: FormMember;
  billingLine1: FormMember;
  billingLine2: FormMember;
  billingCity: FormMember;
  billingState: FormMember;
  billingPostalCode: FormMember;
  billingCountry: FormMember;
  billingPhone: FormMember;
}

export interface CustomerShippingBillingFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: CustomerShippingBillingFormMembers;
}

export const cleanCustomerShippingBillingFormState: CustomerShippingBillingFormState =
  {
    isValid: false,
    isTouched: false,
    isLoading: false,
    isSubmitted: false,
    members: {
      shippingFirstName: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingLastName: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingCompany: {
        isValid: true,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingLine1: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingLine2: {
        isValid: true,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingCity: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingState: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingPostalCode: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingCountry: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      shippingPhone: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      matchAddress: {
        isValid: true,
        isTouched: false,
        isBlurred: true,
        value: true,
        errors: [],
      },
      billingFirstName: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingLastName: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingCompany: {
        isValid: true,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingLine1: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingLine2: {
        isValid: true,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingCity: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingState: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingPostalCode: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingCountry: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
      billingPhone: {
        isValid: false,
        isTouched: false,
        isBlurred: true,
        value: '',
        errors: [],
      },
    },
  };

export const initialCustomerShippingBillingFormState = appStorage.get(
  AppStorageKey.FORM_CUSTOMER_SHIPPING_BILLING_STATE,
  cleanCustomerShippingBillingFormState
) as CustomerShippingBillingFormState;

export const customerShippingBillingFormSlice = createSlice({
  name: 'CustomerShippingBillingForm',
  initialState: initialCustomerShippingBillingFormState,
  reducers: {
    update: (
      state: CustomerShippingBillingFormState,
      action: PayloadAction<CustomerShippingBillingFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_CUSTOMER_SHIPPING_BILLING_STATE, state);
    },
  },
});
