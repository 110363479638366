import { StyledThemeList } from '../interfaces';
import { StyledThemeBase as base } from './base';
import { StyledThemeUK as uk } from './uk';
import { StyledThemeDoctor as doctor } from './doctor';

export const StyledThemes: StyledThemeList = {
  base: { name: 'base', variables: base() },
  uk: { name: 'uk', variables: uk() },
  doctor: { name: 'doctor', variables: doctor() },
};
