import { createSlice } from '@reduxjs/toolkit';
import { updateWindowReducer } from './reducers/updateWindow.reducer';
import { initialWindowState } from './state';

export const windowSlice = createSlice({
  name: 'Window',
  initialState: initialWindowState,
  reducers: {
    updateWindow: updateWindowReducer,
  },
});

export const windowReducer = windowSlice.reducer;
