import { createSlice } from '@reduxjs/toolkit';
import { loadingOrderListReducer } from './reducers/loading-order-list.reducer';
import { orderListErrorReducer } from './reducers/order-list-error.reducer';
import { updateOrderListReducer } from './reducers/update-order-list.reducers';
import { initialOrderListState } from './state';

export const orderListSlice = createSlice({
  name: 'OrderList',
  initialState: initialOrderListState,
  reducers: {
    loading: loadingOrderListReducer,
    update: updateOrderListReducer,
    error: orderListErrorReducer,
  },
});

export const orderListReducer = orderListSlice.reducer;
