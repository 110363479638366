import { JobState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const updateJobReducer = (
  state: JobState,
  action: PayloadAction<JobState>
) => {
  state.sent = action.payload.sent;
  state.available = action.payload.available;
  state.success = action.payload.success;
  state.loading = action.payload.loading;
  state.error = action.payload.error;

  appStorage.set(AppStorageKey.RENEWAL_JOB, state);
};
