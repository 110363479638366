export interface RenewalOrderCalculation {
  shippingPrice: number;
  subTotalPrice: number;
  discountDeductions: number;
  creditDeductions: number;
  taxPrice: number;
  grandTotalPrice: number;
}

export interface ForecastState {
  data: RenewalOrderCalculation | null;
  loading?: boolean;
  error?: string;
}

export const initialForecastState: ForecastState = {
  data: null,
  error: '',
  loading: false,
};
