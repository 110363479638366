import { EmailNotificationState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const updateEmailNotificationReducer = (
  state: EmailNotificationState,
  action: PayloadAction<boolean>
) => {
  state.success = action.payload;
  state.loading = false;
};
