import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface AccountSubscriptionFormMembers {
  renewalDate: FormMember;
  preferredDate: FormMember;
  productId: FormMember;
  planFamilyId: FormMember;
  frequencyId: FormMember;
  dropsQuantity: FormMember;
}

export interface AccountSubscriptionFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: AccountSubscriptionFormMembers;
}

export const cleanAccountSubscriptionFormState: AccountSubscriptionFormState = {
  isValid: true,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    renewalDate: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    preferredDate: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    productId: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    planFamilyId: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    frequencyId: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    dropsQuantity: {
      isValid: true,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialAccountSubscriptionFormState = appStorage.get(
  AppStorageKey.FORM_ACCOUNT_SUBSCRIPTION_STATE,
  cleanAccountSubscriptionFormState
) as AccountSubscriptionFormState;

export const accountSubscriptionFormSlice = createSlice({
  name: 'AccountSubscriptionForm',
  initialState: () => initialAccountSubscriptionFormState,
  reducers: {
    update: (
      state: AccountSubscriptionFormState,
      action: PayloadAction<AccountSubscriptionFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;

      appStorage.set(AppStorageKey.FORM_ACCOUNT_SUBSCRIPTION_STATE, state);
    },
  },
});
