import { Theme, ThemePalette } from '../..';
import { ThemeBasePalette } from './theme-base-palette.theme';
import { ThemeBaseSizing } from './theme-base-sizing.theme';
import { ThemeBaseTypography } from './theme-base-typography.theme';

export const ThemeBase = (palette?: ThemePalette): Theme => {
  if (!palette) palette = ThemeBasePalette();

  return {
    palette,
    sizing: ThemeBaseSizing(),
    typography: ThemeBaseTypography(),
  };
};
