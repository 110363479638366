import { createSlice } from '@reduxjs/toolkit';
import { loadingFaqReducer } from './reducers/loading-faq.reducer';
import { updateFaqReducer } from './reducers/update-faq.reducer';
import { votedFaqReducer } from './reducers/voted-faq.reducer';
import { respondedFaqReducer } from './reducers/responded-faq.reducer';
import { faqErrorReducer } from './reducers/faq-error.reducer';
import { initialFaqState } from './state';

export const faqSlice = createSlice({
  name: 'Faq',
  initialState: initialFaqState,
  reducers: {
    loading: loadingFaqReducer,
    update: updateFaqReducer,
    voted: votedFaqReducer,
    responded: respondedFaqReducer,
    error: faqErrorReducer,
  },
});

export const faqReducer = faqSlice.reducer;
