import { createSlice } from '@reduxjs/toolkit';
import { loadingUserFormAnswerReducer } from './reducers/loading-user-form-answer.reducer';
import { userFormAnswerErrorReducer } from './reducers/user-form-answer-error.reducer';
import { createCustomerUserFormAnswerReducer } from './reducers/create-user-form-answer.reducer';
import { initialUserFormAnswerState } from './state';

export const userFormAnswerSlice = createSlice({
  name: 'UserFormAnswer',
  initialState: initialUserFormAnswerState,
  reducers: {
    loading: loadingUserFormAnswerReducer,
    error: userFormAnswerErrorReducer,
    create: createCustomerUserFormAnswerReducer,
  },
});

export const userFormAnswerReducer = userFormAnswerSlice.reducer;
