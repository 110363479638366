import { combineReducers } from 'redux';

import {
  captureNewsletterFormSlice,
  CaptureNewsletterFormState,
} from './capture-newsletter.redux';

export interface CaptureFormState {
  newsletter: CaptureNewsletterFormState;
}

export const initialCaptureFormState: CaptureFormState = {
  newsletter: captureNewsletterFormSlice.getInitialState(),
};

export const captureFormReducers = combineReducers({
  newsletter: captureNewsletterFormSlice.reducer,
});

export * from './capture-newsletter.redux';
