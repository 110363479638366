import { Faq } from '@hiwaldo/sdk/interfaces';

export interface FaqState {
  data: Faq | null;
  voted: boolean;
  responded: boolean;
  loading?: boolean;
  error?: string;
}

export const initialFaqState: FaqState = {
  data: null,
  voted: false,
  responded: false,
  error: '',
  loading: false,
};
