import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormMember } from '../../../interfaces';

export interface CustomerPasswordFormMembers {
  password: FormMember;
  confirm: FormMember;
}

export interface CustomerPasswordFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: CustomerPasswordFormMembers;
}

export const cleanCustomerPasswordFormState: CustomerPasswordFormState = {
  isValid: false,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    password: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
    confirm: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialCustomerPasswordFormState = cleanCustomerPasswordFormState;

export const customerPasswordFormSlice = createSlice({
  name: 'CustomerPasswordForm',
  initialState: () => initialCustomerPasswordFormState,
  reducers: {
    update: (
      state: CustomerPasswordFormState,
      action: PayloadAction<CustomerPasswordFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;
    },
  },
});
