import { createSlice } from '@reduxjs/toolkit';
import { loadingChargeReducer } from './reducers/loading-charge.reducer';
import { updateChargeReducer } from './reducers/update-charge.reducer';
import { chargeErrorReducer } from './reducers/charge-error.reducer';
import { initialChargeState } from './state';

export const chargeSlice = createSlice({
  name: 'Charge',
  initialState: initialChargeState,
  reducers: {
    loading: loadingChargeReducer,
    update: updateChargeReducer,
    error: chargeErrorReducer,
  },
});

export const chargeReducer = chargeSlice.reducer;
