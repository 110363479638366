import { Customer } from '@hiwaldo/sdk/interfaces';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { CustomerState } from '../state';

export const createCustomerReducer = (
  state: CustomerState,
  action: PayloadAction<Customer>
) => {
  state.customer = action.payload;
  state.authedId = action.payload.id;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
