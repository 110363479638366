import { FunnelState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppStorageKey, appStorage } from '../../../../../helpers';

export const setHasLensesReducer = (
  state: FunnelState,
  action: PayloadAction<boolean>
) => {
  state.hasLenses = action.payload;

  appStorage.set(AppStorageKey.FUNNEL_STATE, state);
};
