import { createSlice } from '@reduxjs/toolkit';
import { showBasketReducer } from './reducers/showBasket.reducer';
import { hideBasketReducer } from './reducers/hideBasket.reducer';
import { createCartReducer } from './reducers/createCart.reducer';
import { updateCartReducer } from './reducers/updateCart.reducer';
import { updateCartDoctorReducer } from './reducers/updateCartDoctor.reducer';
import { updateCartPrescriptionReducer } from './reducers/updateCartPrescription.reducer';
import { setPaypalTokenReducer } from './reducers/setPaypalToken.reducer';
import { setFetchedClientSecretReducer } from './reducers/setFetchedClientSecret.reducer';
import { setPaypalCheckoutStatusReducer } from './reducers/set-paypal-checkout-status.reducer';
import { setPaymentGatewayReducer } from './reducers/setPaymentGateway.reducer';
import { initialCartState } from './state';

export const cartSlice = createSlice({
  name: 'Cart',
  initialState: initialCartState,
  reducers: {
    showBasket: showBasketReducer,
    hideBasket: hideBasketReducer,
    createCart: createCartReducer,
    updateCart: updateCartReducer,
    updateCartDoctor: updateCartDoctorReducer,
    updateCartPrescription: updateCartPrescriptionReducer,
    setPaypalToken: setPaypalTokenReducer,
    setFetchedClientSecret: setFetchedClientSecretReducer,
    setPaymentGateway: setPaymentGatewayReducer,
    setPaypalStatus: setPaypalCheckoutStatusReducer,
    clearCart: () => initialCartState,
  },
});

export const cartReducer = cartSlice.reducer;
