import { createSlice } from '@reduxjs/toolkit';
import { updateEmailNotificationReducer } from './reducers/updateEmailNotify.reducer';
import { errorEmailNotificationReducer } from './reducers/errorEmailNotify.reducer';
import { loadingEmailNotificationReducer } from './reducers/loadingEmailNotify.reducer';
import { initialEmailNotificationState } from './state';

export const emailNotificationSlice = createSlice({
  name: 'EmailNotification',
  initialState: initialEmailNotificationState,
  reducers: {
    update: updateEmailNotificationReducer,
    error: errorEmailNotificationReducer,
    loading: loadingEmailNotificationReducer,
  },
});

export const emailNotificationReducer = emailNotificationSlice.reducer;
