import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';

import { BillingAddress, Customer } from '@hiwaldo/sdk/interfaces';

export const createCustomerBillingAddressReducer = (
  state: CustomerState,
  action: PayloadAction<BillingAddress>
) => {
  (state.customer as Customer).billingAddress = action.payload;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
