import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { CartState } from '../../../orders';
import { Prescription } from '@hiwaldo/sdk/interfaces';
import { CustomerSubscription } from '@hiwaldo/sdk/interfaces/renewal';

export const customerCheckoutReducer = (
  state: CustomerState,
  action: PayloadAction<CartState>
) => {
  const preliminaryRenewalDate = new Date();
  preliminaryRenewalDate.setTime(
    preliminaryRenewalDate.getTime() + 86400000 * 12
  );

  const { cart, includesTrial, includesDoctor, includesPrescription } =
    action.payload;

  if (includesTrial) {
    const customerSubscription = {
      id: 0,
      status: 'PENDING',
      region: 'PST',
      nextRenewal: preliminaryRenewalDate.toISOString(),
      lastRenewal: undefined,
      frequency: 84,
      preferredDay: undefined,
      preferredDate: undefined,
      resumeDate: undefined,
      plan: {
        id: cart!.trials[0].trial.plans[1].plan.id,
        planFamily: cart!.trials[0].trial.plans[1].plan.planFamily,
        title: cart!.trials[0].trial.plans[1].plan.title,
        handle: cart!.trials[0].trial.plans[1].plan.handle,
        frequencies: [
          {
            frequency: {
              frequency: 84,
            },
          },
          {
            frequency: {
              frequency: 112,
            },
          },
          {
            frequency: {
              frequency: 140,
            },
          },
        ],
        pauseOptions: [
          {
            pauseOption: {
              value: 7,
            },
          },
          {
            pauseOption: {
              value: 14,
            },
          },
          {
            pauseOption: {
              value: 21,
            },
          },
        ],
        products: cart!.trials[0].trial.plans[1].plan.products,
        locales: cart!.trials[0].trial.plans[1].plan.locales,
      },
      renewalOrder: null,
      pausedDate: null,
      reactivatedDate: null,
      productVariants: [],
    } as any as CustomerSubscription;

    state.customer!.activeCustomerSubscription = customerSubscription;
  }

  if (includesPrescription) {
    const activePrescription = {
      id: 0,
      status: 'PENDING',
      expires: null,
      doctorAuth: cart?.doctor
        ? {
            id: 0,
            status: 'RATIFICATION',
            reviewAt: 0,
            doctorId: null,
            doctorParty: {
              firstName: cart!.doctor!.firstName,
              lastName: cart!.doctor!.lastName,
              company: cart!.doctor!.company,
              phone: cart!.doctor!.phone,
            },
          }
        : undefined,
      leftEye: cart!.prescription!.leftEyeProductVariant
        ? {
            power:
              cart!.prescription!.leftEyeProductVariant.attributes[0].attribute
                .value,
            eyeType: 'LEFT',
            productVariantId: cart!.prescription!.leftEyeProductVariant.id,
          }
        : undefined,
      rightEye: cart!.prescription!.rightEyeProductVariant
        ? {
            power:
              cart!.prescription!.rightEyeProductVariant.attributes[0].attribute
                .value,
            eyeType: 'RIGHT',
            productVariantId: cart!.prescription!.rightEyeProductVariant.id,
          }
        : undefined,
    } as any as Prescription;

    state.customer!.activePrescription = activePrescription;
  }

  state.isSubscriber = includesTrial;
  state.isVerified = !includesDoctor;
  state.hasPrescription = includesPrescription;
  state.statusDoctorAuth = null;
  state.hasRenewalDate = false;
  state.converted = true;
};
