import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';
import {
  Order,
  CatalogProductVariant,
  RenewalOrder,
  Cart,
} from '@hiwaldo/sdk/interfaces';

export interface OrderDataLatest {
  countItems: number;
  countProductVariants: number;
  countBundles: number;
  countTrials: number;
  includesLenses: boolean;
  includesTrial: boolean;
  includesBundle: boolean;
  includesBothEyes: boolean;
  includesDuplicateEyes: boolean;
  includesBLG: boolean;
  includesDrops: boolean;
  includesDoctor: boolean;
  includesPrescription: boolean;
  includesTax: boolean;

  cart: Cart;
  order: Order;
  renewalForecast?: RenewalOrder;
  leftEye?: CatalogProductVariant;
  rightEye?: CatalogProductVariant;
}

export interface OrderDataState {
  latestOrder?: OrderDataLatest;
  countOrders?: number;
  orders?: Order[];
}

export const cleanOrderDataState: OrderDataState = {};

export const initialOrderDataState = appStorage.get(
  AppStorageKey.DATA_ORDER_STATE,
  cleanOrderDataState
) as OrderDataState;

export const orderDataSlice = createSlice({
  name: 'OrderData',
  initialState: initialOrderDataState,
  reducers: {
    update: (state: OrderDataState, action: PayloadAction<OrderDataState>) => {
      state.latestOrder = action.payload.latestOrder;
      state.countOrders = action.payload.countOrders;
      state.orders = action.payload.orders;

      appStorage.set(AppStorageKey.DATA_ORDER_STATE, state);

      return state;
    },
  },
});
