import { createSlice } from '@reduxjs/toolkit';
import { loadingForecastReducer } from './reducers/loading-forecast.reducer';
import { updateForecastReducer } from './reducers/update-forecast.reducer';
import { forecastErrorReducer } from './reducers/forecast-error.reducer';
import { initialForecastState } from './state';

export const forecastSlice = createSlice({
  name: 'Forecast',
  initialState: initialForecastState,
  reducers: {
    loading: loadingForecastReducer,
    update: updateForecastReducer,
    error: forecastErrorReducer,
  },
});

export const forecastReducer = forecastSlice.reducer;
