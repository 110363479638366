import { StyledTheme, StyledThemePalette } from '../..';
import { StyledThemeBase } from '../base';
import { StyledThemeUKPalette } from './styled-theme-uk-palette.theme';

export const StyledThemeUK = (palette?: StyledThemePalette): StyledTheme => {
  if (!palette) palette = StyledThemeUKPalette();

  return {
    ...StyledThemeBase(palette),
  };
};
