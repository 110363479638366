import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';

export interface WelcomePageState {
  shown: boolean;
}

export const cleanWelcomePageState: WelcomePageState = {
  shown: false,
};

export const initialWelcomePageState = appStorage.get(
  AppStorageKey.PAGE_WELCOME_STATE,
  cleanWelcomePageState
) as WelcomePageState;

export const welcomePageSlice = createSlice({
  name: 'WelcomePage',
  initialState: initialWelcomePageState,
  reducers: {
    update: (
      state: WelcomePageState,
      action: PayloadAction<WelcomePageState>
    ) => {
      state.shown = action.payload.shown;

      appStorage.set(AppStorageKey.PAGE_WELCOME_STATE, state);

      return state;
    },
  },
});
