import { combineReducers } from 'redux';
import { PlanListState, initialPlanListState } from './plan-list/state';
import { ProductState, initialProductState } from './product/state';
import {
  ProductVariantState,
  initialProductVariantState,
} from './product-variant/state';
import {
  ProductListState,
  initialProductListState,
} from './product-list/state';
import { TrialState, initialTrialState } from './trial/state';
import { DiscountState, initialDiscountState } from './discount/state';
import { planListReducer } from './plan-list/slice';
import { productReducer } from './product/slice';
import { productListReducer } from './product-list/slice';
import { productVariantReducer } from './product-variant/slice';
import { trialReducer } from './trial/slice';
import { discountReducer } from './discount/slice';

export * from './plan-list';
export * from './product';
export * from './product-list';
export * from './product-variant';
export * from './trial';
export * from './discount';

export type CatalogState = {
  planList: PlanListState;
  product: ProductState;
  productVariant: ProductVariantState;
  productList: ProductListState;
  trial: TrialState;
  discount: DiscountState;
};

export const initialCatalogState: CatalogState = {
  planList: initialPlanListState,
  product: initialProductState,
  productVariant: initialProductVariantState,
  productList: initialProductListState,
  trial: initialTrialState,
  discount: initialDiscountState,
};

export const catalogReducers = combineReducers({
  planList: planListReducer,
  product: productReducer,
  productVariant: productVariantReducer,
  productList: productListReducer,
  trial: trialReducer,
  discount: discountReducer,
});
