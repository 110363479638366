import { CatalogProduct } from '@hiwaldo/sdk/interfaces';

export interface ProductState {
  data: CatalogProduct | null;
  loading?: boolean;
  error?: string;
}

export const initialProductState: ProductState = {
  data: null,
  error: '',
  loading: false,
};
