// import { FaqList } from '@hiwaldo/sdk/interfaces';

export interface FaqListState {
  loading: boolean;
  error: string;
  list: any;
  search: FaqListSearchState;
}

export interface FaqListSearchState {
  page: number;
  filter: {
    skip: number;
    take: number;
    where: {
      id?: string;
      search?: string;
    };
  };
}

export const initialFaqListSearchState = {
  page: 0,
  filter: {
    skip: 0,
    take: 12,
    where: {},
  },
};

export const initialFaqListState = {
  loading: false,
  error: '',
  search: initialFaqListSearchState,
  list: {
    rows: 0,
    data: [],
  },
};
