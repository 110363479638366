import { createSlice } from '@reduxjs/toolkit';
import { loadingProductReducer } from './reducers/loading-product.reducer';
import { updateProductReducer } from './reducers/update-product.reducer';
import { productErrorReducer } from './reducers/product-error.reducer';
import { initialProductState } from './state';

export const productSlice = createSlice({
  name: 'Product',
  initialState: initialProductState,
  reducers: {
    loading: loadingProductReducer,
    update: updateProductReducer,
    error: productErrorReducer,
  },
});

export const productReducer = productSlice.reducer;
