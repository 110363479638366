import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const IconBaseStyles = css<{ theme: StyledTheme }>`
  img {
    width: 3rem;
  }

  img.rotate180 {
    transform: rotate(180deg);
  }
`;
