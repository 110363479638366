import { CustomerState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const authedCustomerReducer = (
  state: CustomerState,
  action: PayloadAction<Customer>
) => {
  if (
    action.payload.activeCustomerSubscription &&
    !action.payload.activeCustomerSubscription.nextRenewal
  ) {
    const preliminaryRenewalDate = new Date();
    preliminaryRenewalDate.setTime(
      preliminaryRenewalDate.getTime() + 86400000 * 12
    );

    state.customer = {
      ...action.payload,
      activeCustomerSubscription: {
        ...action.payload.activeCustomerSubscription,
        nextRenewal: preliminaryRenewalDate.toISOString(),
      },
    };
  } else {
    state.customer = action.payload;
  }

  state.loggedIn = true;
  state.authedId = action.payload.id;
  state.registered = false;

  const leftEye = state.customer.activePrescription?.leftEye || undefined;
  const rightEye = state.customer.activePrescription?.rightEye || undefined;
  state.hasPrescription = !!state.customer.activePrescription;
  state.statusDoctorAuth = state.customer.activePrescription?.status || null;
  state.includesBothEyes = !!(leftEye && rightEye);

  const subscription = state.customer.activeCustomerSubscription
    ? state.customer.activeCustomerSubscription
    : undefined;

  state.hasRenewalDate = !!subscription?.nextRenewal;

  appStorage.set(AppStorageKey.CUSTOMER_STATE, state);
};
