import { createSlice } from '@reduxjs/toolkit';
import { initialDoctorListState } from './state';
import { doctorListErrorReducer } from './reducers/doctor-list-error.reducer';
import { loadingDoctorListReducer } from './reducers/loading-doctor-list.reducer';
import { searchDoctorListReducer } from './reducers/search-doctor-list.reducer';
import { updateDoctorListReducer } from './reducers/update-doctor-list.reducer';

export const doctorListSlice = createSlice({
  name: 'DoctorList',
  initialState: initialDoctorListState,
  reducers: {
    loading: loadingDoctorListReducer,
    search: searchDoctorListReducer,
    update: updateDoctorListReducer,
    error: doctorListErrorReducer,
  },
});

export const doctorListReducer = doctorListSlice.reducer;
