import { DoctorList } from '@hiwaldo/sdk/interfaces';

export interface DoctorListState {
  search: DoctorListSearchState;
  list: DoctorList;
  loading?: boolean;
  error?: string;
}

export interface DoctorListSearchState {
  page: number;
  filter: {
    skip: number;
    take: number;
    where: {
      id?: string;
      name?: string;
      state?: string;
      search?: string;
    };
  };
}

export const initialDoctorListSearchState: DoctorListSearchState = {
  page: 0,
  filter: {
    skip: 0,
    take: 15,
    where: {},
  },
};

export const initialDoctorListState: DoctorListState = {
  search: initialDoctorListSearchState,
  list: { rows: 0, data: [] },
  error: '',
  loading: false,
};
