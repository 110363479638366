export interface Clinic {
  id: string;
  title?: string;
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  county?: string;
  state?: string;
  country?: string;
  phone?: string;
  faxNumber?: string;
  type?: string;
  dedicated?: boolean;
  distance?: number;
  latitude?: number;
  longitude?: number;
  googlePlaceId?: string;
  rank?: number;
  externalId?: number;
  members: {
    id: string;
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    active?: boolean;
    rank?: number;
  }[];
}

export interface ClinicState {
  data: Clinic | null;
  loading?: boolean;
  error?: string;
}

export const initialClinicState: ClinicState = {
  data: null,
  error: '',
  loading: false,
};
