import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';

export interface MenuPageState {
  navbar: 'main-menu' | 'shop-all' | 'about-us' | 'for-doctors' | false;
  account: boolean;
}

export const cleanMenuPageState: MenuPageState = {
  navbar: false,
  account: false,
};

export const initialMenuPageState = appStorage.get(
  AppStorageKey.PAGE_MENU_STATE,
  cleanMenuPageState
) as MenuPageState;

export const menuPageSlice = createSlice({
  name: 'MenuPage',
  initialState: initialMenuPageState,
  reducers: {
    update: (state: MenuPageState, action: PayloadAction<MenuPageState>) => {
      state.navbar = action.payload.navbar;
      state.account = action.payload.account;

      appStorage.set(AppStorageKey.PAGE_MENU_STATE, state);

      return state;
    },
  },
});
