import { useEffect } from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from 'react-jss';
import { Region } from '@hiwaldo/sdk';
import { useApollo } from '../server';
import { useStore } from '../state';
import { Themes } from '../jss';
import { ToastProvider } from '../components';
import { StyledThemes } from '../styles';
import 'tailwindcss/tailwind.css';
import '../css/main.css';
import '../css/gladly.css';
import '../css/slick.css';

function MyApp({ Component, pageProps }: AppProps): any {
  const region: Region =
    (process.env.REGION as Region) ||
    (process.env.NEXT_PUBLIC_REGION as Region);

  const store = useStore(pageProps.initialReduxState);
  const apolloClient = useApollo(pageProps.initialApolloState);

  const theme = Themes.base.variables;
  const styledTheme =
    region === 'US' ? StyledThemes.base.variables : StyledThemes.uk.variables;

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js');
      });
    }
  });

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={styledTheme}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1"
              />
            </Head>
            <ToastProvider>
              <Component {...pageProps} />
            </ToastProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default MyApp;
