import { Clinic } from '../clinic/state';

export interface ExtendedClinic extends Clinic {
  distance?: number;
  latitude?: number;
  longitude?: number;
  googlePlaceId?: string;
  rank?: number;
  externalId?: number;
}

export interface ClinicList {
  rows: number;
  data: ExtendedClinic[];
}

export interface ClinicListState {
  search: ClinicListSearchState;
  list: ClinicList;
  loading?: boolean;
  error?: string;
}

export interface ClinicListSearchState {
  page: number;
  filter: {
    skip: number;
    take: number;
    where: {
      latitude?: number;
      longitude?: number;
      distance?: number;
    };
  };
}

export const initialClinicListSearchState: ClinicListSearchState = {
  page: 0,
  filter: {
    skip: 0,
    take: 15,
    where: {},
  },
};

export const initialClinicListState: ClinicListState = {
  search: initialClinicListSearchState,
  list: { rows: 0, data: [] },
  error: '',
  loading: false,
};
