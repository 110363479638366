import { useMemo } from 'react';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { commonReducers, CommonState, initialCommonState } from './common';
import { catalogReducers, CatalogState, initialCatalogState } from './catalog';
import { initialOrdersState, ordersReducers, OrdersState } from './orders';
import {
  customersReducers,
  CustomersState,
  initialCustomersState,
} from './customers';
import { doctorsReducers, DoctorsState, initialDoctorsState } from './doctors';
import { dataReducers, DataState, initialDataState } from './data';
import { formReducers, FormState, initialFormState } from './form';
import { pageReducers, PageState, initialPageState } from './page';
import { miscReducers, MiscState, initialMiscState } from './misc';
import { renewalReducers, RenewalState, initialRenewalState } from './renewal';
import {
  partnersReducers,
  PartnersState,
  initialPartnersState,
} from './partners';

export * from './catalog';
export * from './catalog';
export * from './customers';
export * from './orders';
export * from './doctors';
export * from './data';
export * from './form';
export * from './page';
export * from './misc';
export * from './renewal';
export * from './partners';

export type StoreState = {
  common: CommonState;
  catalog: CatalogState;
  customers: CustomersState;
  orders: OrdersState;
  doctors: DoctorsState;
  misc: MiscState;
  renewal: RenewalState;
  partners: PartnersState;
  data: DataState;
  form: FormState;
  page: PageState;
};

let store: any;

export const initialState: StoreState = {
  common: initialCommonState,
  catalog: initialCatalogState,
  customers: initialCustomersState,
  orders: initialOrdersState,
  doctors: initialDoctorsState,
  misc: initialMiscState,
  renewal: initialRenewalState,
  partners: initialPartnersState,
  data: initialDataState,
  form: initialFormState,
  page: initialPageState,
};

const reducers = combineReducers({
  common: commonReducers,
  catalog: catalogReducers,
  customers: customersReducers,
  orders: ordersReducers,
  doctors: doctorsReducers,
  misc: miscReducers,
  renewal: renewalReducers,
  partners: partnersReducers,
  data: dataReducers,
  form: formReducers,
  page: pageReducers,
});

function initStore(preloadedState = initialState) {
  return configureStore({
    reducer: reducers,
    preloadedState,
    devTools: process.env.NEXT_PUBLIC_APP_ENV !== 'prod',
  });
}

export const initializeStore = (preloadedState: StoreState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });

    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;

  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState: any) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
