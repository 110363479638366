import { CartState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { PayloadAction } from '@reduxjs/toolkit';

export const setPaypalTokenReducer = (
  state: CartState,
  action: PayloadAction<string>
) => {
  state.paypal.paypalToken = action.payload;
  appStorage.set(AppStorageKey.CART_STATE, state);

  return state;
};
