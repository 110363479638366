import { PlanListSearchState, PlanListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';

export const searchPlanListReducer = (
  state: PlanListState,
  action: PayloadAction<PlanListSearchState>
) => {
  state.search = action.payload;
  state.loaded = false;
};
