import { createSlice } from '@reduxjs/toolkit';
import { initialFaqListState } from './state';
import { loadingFaqListReducer } from './reducers/loading-faq-list.reducer';
import { updateFaqListReducer } from './reducers/update-faq-list.reducer';
import { faqListErrorReducer } from './reducers/faq-list-error.reducer';

export const faqListSlice = createSlice({
  name: 'FaqList',
  initialState: initialFaqListState,
  reducers: {
    loading: loadingFaqListReducer,
    update: updateFaqListReducer,
    error: faqListErrorReducer,
  },
});

export const faqListReducer = faqListSlice.reducer;
