import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { FormMember } from '../../../interfaces';

export interface CheckoutStripeFormMembers {
  cardHolder: FormMember;
}

export interface CheckoutStripeRequest {
  country: string;
  currency: string;
  total: {
    label: string;
    amount: number;
  };
  requestPayerName: boolean;
  requestPayerEmail: boolean;
}

export interface CheckoutStripeFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  cartId?: number;
  members: CheckoutStripeFormMembers;
  request?: CheckoutStripeRequest;
}

export const cleanCheckoutStripeFormState: CheckoutStripeFormState = {
  isValid: false,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    cardHolder: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialCheckoutStripeFormState = appStorage.get(
  AppStorageKey.FORM_CHECKOUT_STRIPE_STATE,
  cleanCheckoutStripeFormState
) as CheckoutStripeFormState;

export const checkoutStripeFormSlice = createSlice({
  name: 'CheckoutStripeForm',
  initialState: () => initialCheckoutStripeFormState,
  reducers: {
    update: (
      state: CheckoutStripeFormState,
      action: PayloadAction<CheckoutStripeFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.cartId = action.payload.cartId;
      state.members = action.payload.members;
      state.request = action.payload.request;

      appStorage.set(AppStorageKey.FORM_CHECKOUT_STRIPE_STATE, state);
    },
  },
});
