import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../helpers';
import { CheckoutStep } from '../../data';

export interface CheckoutFormFlowStep {
  isCompleted: boolean;
  isTouched: boolean;
  isValid: boolean;
  isActive: boolean;
}

export interface CheckoutFormFlowState {
  isValid: boolean;
  isTouched: boolean;
  currentStep?: CheckoutStep;
  steps: {
    registration: CheckoutFormFlowStep;
    login: CheckoutFormFlowStep;
    shippingBilling: CheckoutFormFlowStep;
    review: CheckoutFormFlowStep;
    payment: CheckoutFormFlowStep;
  };
}

export const cleanCheckoutFormFlowState: CheckoutFormFlowState = {
  isValid: false,
  isTouched: false,
  steps: {
    registration: {
      isCompleted: false,
      isTouched: false,
      isValid: false,
      isActive: false,
    },
    login: {
      isCompleted: false,
      isTouched: false,
      isValid: false,
      isActive: false,
    },
    shippingBilling: {
      isCompleted: false,
      isTouched: false,
      isValid: false,
      isActive: false,
    },
    review: {
      isCompleted: false,
      isTouched: false,
      isValid: false,
      isActive: false,
    },
    payment: {
      isCompleted: false,
      isTouched: false,
      isValid: false,
      isActive: false,
    },
  },
};

export const initialCheckoutFormFlowState = appStorage.get(
  AppStorageKey.FORM_CHECKOUT_FLOW_STATE,
  cleanCheckoutFormFlowState
) as CheckoutFormFlowState;

export const checkoutFormFlowSlice = createSlice({
  name: 'CheckoutFormFlow',
  initialState: initialCheckoutFormFlowState,
  reducers: {
    update: (
      state: CheckoutFormFlowState,
      action: PayloadAction<CheckoutFormFlowState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.currentStep = action.payload.currentStep;
      state.steps = action.payload.steps;

      appStorage.set(AppStorageKey.FORM_CHECKOUT_FLOW_STATE, state);

      return state;
    },
  },
});
