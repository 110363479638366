import {
  userFormReducer,
  UserFormState,
  initialUserFormState,
} from './userForm';
import {
  userFormAnswerReducer,
  UserFormAnswerState,
  initialUserFormAnswerState,
} from './userFormAnswer';
import { faqReducer, FaqState, initialFaqState } from './faq';
import { faqListReducer, FaqListState, initialFaqListState } from './faq-list';

import { combineReducers } from 'redux';
import {
  emailNotificationReducer,
  EmailNotificationState,
  initialEmailNotificationState,
} from './emailNotification';

export interface MiscState {
  userForm: UserFormState;
  userFormAnswer: UserFormAnswerState;
  faq: FaqState;
  faqList: FaqListState;
  emailNotification: EmailNotificationState;
}

export const initialMiscState: MiscState = {
  userForm: initialUserFormState,
  userFormAnswer: initialUserFormAnswerState,
  faq: initialFaqState,
  faqList: initialFaqListState,
  emailNotification: initialEmailNotificationState,
};

export const miscReducers = combineReducers({
  userForm: userFormReducer,
  userFormAnswer: userFormAnswerReducer,
  faq: faqReducer,
  faqList: faqListReducer,
  emailNotification: emailNotificationReducer,
});
