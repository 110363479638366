import { DoctorListState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { DoctorList } from '@hiwaldo/sdk/interfaces';

export const updateDoctorListReducer = (
  state: DoctorListState,
  action: PayloadAction<DoctorList>
) => {
  state.loading = false;
  state.list = action.payload;
};
