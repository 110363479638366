import React from 'react';
import { IconStyles, IconTemplateProps } from '../';
import { IoIosNotifications } from 'react-icons/io';

export const IconNotificationTemplate = (props: IconTemplateProps) => {
  return (
    <IconStyles className={props.classes}>
      <IoIosNotifications />
    </IconStyles>
  );
};
