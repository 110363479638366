import { UserFormState } from '../state';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserForm } from '@hiwaldo/sdk/interfaces';

export const updateUserFormReducer = (
  state: UserFormState,
  action: PayloadAction<UserForm>
) => {
  state.loading = false;
  state.data = action.payload;
};
