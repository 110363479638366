import { PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { CustomerAccountState } from '../state';

export const loadedBillingReducer = (
  state: CustomerAccountState,
  action: PayloadAction<boolean>
) => {
  state.billing = action.payload;

  appStorage.set(AppStorageKey.ACCOUNT_STATE, state);
};
