import { combineReducers } from 'redux';

import {
  checkoutFormFlowSlice,
  CheckoutFormFlowState,
} from './checkout-flow.redux';
import {
  checkoutStripeFormSlice,
  CheckoutStripeFormState,
} from './checkout-stripe.redux';

export interface CheckoutFormState {
  flow: CheckoutFormFlowState;
  stripe: CheckoutStripeFormState;
}

export const initialCheckoutFormState: CheckoutFormState = {
  flow: checkoutFormFlowSlice.getInitialState(),
  stripe: checkoutStripeFormSlice.getInitialState(),
};

export const checkoutFormReducers = combineReducers({
  flow: checkoutFormFlowSlice.reducer,
  stripe: checkoutStripeFormSlice.reducer,
});

export * from './checkout-flow.redux';
export * from './checkout-stripe.redux';
