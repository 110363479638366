import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GTMPageState {
  dataLayer: any[];
  revrollDataLayer: any[];
}

export const gtmPageSlice = createSlice({
  name: 'GTMPage',
  initialState: {
    dataLayer: [],
    revrollDataLayer: [],
  } as GTMPageState,
  reducers: {
    update: (state: GTMPageState, action: PayloadAction<any>) => {
      state.dataLayer.push({ ecommerce: null });
      state.dataLayer.push(action.payload);

      if (typeof window !== 'undefined' && (window as any).dataLayer) {
        (window as any).dataLayer.push(action.payload);
      }
    },
    revrollUpdate: (state: GTMPageState, action: PayloadAction<any>) => {
      state.revrollDataLayer.push({ ecommerce: null });
      state.revrollDataLayer.push(action.payload);

      if (typeof window !== 'undefined' && (window as any).revrollDataLayer) {
        (window as any).revrollDataLayer.push({ ecommerce: null });
        (window as any).revrollDataLayer.push(action.payload);
      }
    },
  },
});
