import { Order } from '@hiwaldo/sdk/interfaces';

export interface OrderState {
  savingOrder: boolean;
  data: Order | null;
  loading?: boolean;
  error?: string;
}

export const initialOrderState: OrderState = {
  savingOrder: false,
  data: null,
  error: '',
  loading: false,
};
