import { OrderList } from '@hiwaldo/sdk/interfaces';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrderListState } from '../state';

export const updateOrderListReducer = (
  state: OrderListState,
  action: PayloadAction<OrderList>
) => {
  state.loading = false;
  state.list = action.payload;
};
