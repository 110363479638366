import { combineReducers } from 'redux';
import {
  accountCancelFormSlice,
  AccountCancelFormState,
} from './account-cancel.redux';
import {
  accountPauseFormSlice,
  AccountPauseFormState,
} from './account-pause.redux';
import {
  accountReactivationFormSlice,
  AccountReactivationFormState,
} from './account-reactivation.redux';
import {
  accountResumeFormSlice,
  AccountResumeFormState,
} from './account-resume.redux';
import {
  accountPrescriptionFormSlice,
  AccountPrescriptionFormState,
} from './account-prescription.redux';
import {
  accountStripeFormSlice,
  AccountStripeFormState,
} from './account-stripe.redux';
import {
  accountSubscriptionFormSlice,
  AccountSubscriptionFormState,
} from './account-subscription.redux';

export interface AccountFormState {
  cancel: AccountCancelFormState;
  pause: AccountPauseFormState;
  reactivation: AccountReactivationFormState;
  resume: AccountResumeFormState;
  prescription: AccountPrescriptionFormState;
  stripe: AccountStripeFormState;
  subscription: AccountSubscriptionFormState;
}

export const initialAccountFormState: AccountFormState = {
  cancel: accountCancelFormSlice.getInitialState(),
  pause: accountPauseFormSlice.getInitialState(),
  reactivation: accountReactivationFormSlice.getInitialState(),
  resume: accountResumeFormSlice.getInitialState(),
  prescription: accountPrescriptionFormSlice.getInitialState(),
  stripe: accountStripeFormSlice.getInitialState(),
  subscription: accountSubscriptionFormSlice.getInitialState(),
};

export const accountFormReducers = combineReducers({
  cancel: accountCancelFormSlice.reducer,
  pause: accountPauseFormSlice.reducer,
  reactivation: accountReactivationFormSlice.reducer,
  resume: accountResumeFormSlice.reducer,
  prescription: accountPrescriptionFormSlice.reducer,
  stripe: accountStripeFormSlice.reducer,
  subscription: accountSubscriptionFormSlice.reducer,
});

export * from './account-cancel.redux';
export * from './account-pause.redux';
export * from './account-reactivation.redux';
export * from './account-resume.redux';
export * from './account-prescription.redux';
export * from './account-stripe.redux';
export * from './account-subscription.redux';
