import {
  geolocationReducer,
  GeolocationState,
  initialGeolocationState,
} from './geolocation';
import { gtmReducer, GTMState, initialGTMState } from './gtm';
import {
  newsletterSignUpReducer,
  NewsletterSignUpState,
  initialNewsletterSignUpState,
} from './newsletter';
import { windowReducer, WindowState, initialWindowState } from './window';

import { combineReducers } from 'redux';

export type CommonState = {
  geolocation: GeolocationState;
  gtm: GTMState;
  newsletterSignUp: NewsletterSignUpState;
  window: WindowState;
};

export const initialCommonState: CommonState = {
  geolocation: initialGeolocationState,
  gtm: initialGTMState,
  newsletterSignUp: initialNewsletterSignUpState,
  window: initialWindowState,
};

export const commonReducers = combineReducers({
  geolocation: geolocationReducer,
  gtm: gtmReducer,
  newsletterSignUp: newsletterSignUpReducer,
  window: windowReducer,
});
