import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormMember } from '../../../interfaces';

export interface CustomerResetFormMembers {
  email: FormMember;
}

export interface CustomerResetFormState {
  isValid: boolean;
  isTouched: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  members: CustomerResetFormMembers;
}

export const cleanCustomerResetFormState: CustomerResetFormState = {
  isValid: false,
  isTouched: false,
  isLoading: false,
  isSubmitted: false,
  members: {
    email: {
      isValid: false,
      isTouched: false,
      isBlurred: true,
      value: '',
      errors: [],
    },
  },
};

export const initialCustomerResetFormState = cleanCustomerResetFormState;

export const customerResetFormSlice = createSlice({
  name: 'CustomerResetForm',
  initialState: () => initialCustomerResetFormState,
  reducers: {
    update: (
      state: CustomerResetFormState,
      action: PayloadAction<CustomerResetFormState>
    ) => {
      state.isValid = action.payload.isValid;
      state.isTouched = action.payload.isTouched;
      state.isLoading = action.payload.isLoading;
      state.isSubmitted = action.payload.isSubmitted;
      state.members = action.payload.members;
    },
  },
});
