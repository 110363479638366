import { GeolocationState } from '../state';
import { appStorage, AppStorageKey } from '../../../../../helpers';

export const clearGeolocationReducer = (state: GeolocationState) => {
  state.geoRegion = null;
  state.country = null;
  state.loading = false;
  state.redirected = false;

  appStorage.set(AppStorageKey.GEOLOCATION_STATE, state);
};
