import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appStorage, AppStorageKey } from '../../../helpers';

export interface GeolocationPageState {
  called: boolean;
  redirected: boolean;
  loading: boolean;
  geoRegion?: string;
  geoCountry?: string;
  errors?: string[];
}

export const cleanGeolocationPageState: GeolocationPageState = {
  called: false,
  redirected: false,
  loading: false,
};

export const initialGeolocationPageState = appStorage.get(
  AppStorageKey.PAGE_GEOLOCATION_STATE,
  cleanGeolocationPageState
) as GeolocationPageState;

export const geolocationPageSlice = createSlice({
  name: 'GeolocationPage',
  initialState: initialGeolocationPageState,
  reducers: {
    update: (
      state: GeolocationPageState,
      action: PayloadAction<GeolocationPageState>
    ) => {
      state.called = action.payload.called;
      state.redirected = action.payload.redirected;
      state.loading = action.payload.loading;
      state.geoRegion = action.payload.geoRegion;
      state.geoCountry = action.payload.geoCountry;
      state.errors = action.payload.errors;

      appStorage.set(AppStorageKey.PAGE_GEOLOCATION_STATE, state);

      return state;
    },
  },
});
