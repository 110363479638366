import { combineReducers } from 'redux';

import {
  customerLoginFormSlice,
  CustomerLoginFormState,
} from './customer-login.redux';
import {
  customerPasswordFormSlice,
  CustomerPasswordFormState,
} from './customer-password.redux';
import {
  customerRegistrationFormSlice,
  CustomerRegistrationFormState,
} from './customer-registration.redux';
import {
  customerResetFormSlice,
  CustomerResetFormState,
} from './customer-reset.redux';
import {
  customerShippingBillingFormSlice,
  CustomerShippingBillingFormState,
} from './customer-shipping-billing.redux';

export interface CustomerFormState {
  login: CustomerLoginFormState;
  password: CustomerPasswordFormState;
  registration: CustomerRegistrationFormState;
  reset: CustomerResetFormState;
  shippingBilling: CustomerShippingBillingFormState;
}

export const initialCustomerFormState: CustomerFormState = {
  login: customerLoginFormSlice.getInitialState(),
  password: customerPasswordFormSlice.getInitialState(),
  registration: customerRegistrationFormSlice.getInitialState(),
  reset: customerResetFormSlice.getInitialState(),
  shippingBilling: customerShippingBillingFormSlice.getInitialState(),
};

export const customerFormReducers = combineReducers({
  login: customerLoginFormSlice.reducer,
  password: customerPasswordFormSlice.reducer,
  registration: customerRegistrationFormSlice.reducer,
  reset: customerResetFormSlice.reducer,
  shippingBilling: customerShippingBillingFormSlice.reducer,
});

export * from './customer-login.redux';
export * from './customer-password.redux';
export * from './customer-registration.redux';
export * from './customer-reset.redux';
export * from './customer-shipping-billing.redux';
